import React, { useState, useEffect } from 'react';
import './styles.scss';
import _ from 'lodash';
import { Rings } from 'react-loader-spinner';
import { useLocation } from 'react-router-dom';
import Card from '../../components/card/Card';
import TopNav from './components/topNav/TopNav';
import SearchBar from '../../components/searchBar/SearchBar';
import TableDropdown from './components/tableDropdown/TableDropdown';
import EmptyState from '../../feature/overview/components/emptyState/EmptyState';

import { getAllCampaigns } from '../../services/campaign/campaignService';
import { buildCampaignDetailsFromFetchedCampaign } from '../../utils/campaignUtils';

function CampaignManager() {
  const [isLoading, setIsLoading] = useState(true);
  const [campaignList, setCampaignList] = useState([]);
  const [filterByStatus, setFilterByStatus] = useState('');
  const [filterByClientName, setFilterByClientName] = useState('');
  const [draftNavigation, setDraftNavigation] = useState({ clientName: '' });
  const regExp = RegExp(
    `.*${filterByClientName.toLowerCase().split('').join('.*')}.*`
  );
  const location = useLocation();

  useEffect(() => {
    fetchAllCampaigns();
    setFilterByClientName('');

    if (!_.isEmpty(location.state)) {
      setDraftNavigation({
        clientName: location.state.clientName,
      });
      setFilterByStatus('draft');
    }
  }, [filterByStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const grupedBy = (arr, property) => {
    return arr.reduce(function (total, obj) {
      let key = obj[property];
      if (!total[key]) {
        total[key] = [];
      }
      total[key].push(obj);
      return total;
    }, {});
  };

  const fetchAllCampaigns = async () => {
    setIsLoading(true);
    let fetchedCampaigns = await getAllCampaigns(filterByStatus);
    if (!_.isEmpty(fetchedCampaigns)) {
      let fixedList = fetchedCampaigns.map((fetchedCampaign) => {
        return buildCampaignDetailsFromFetchedCampaign(fetchedCampaign);
      });
      setCampaignList(grupedBy(fixedList, 'clientName'));
    } else {
      setCampaignList([]);
    }
    setIsLoading(false);
  };

  const handleFilterByStatus = (status) => {
    setFilterByStatus(status);
    location.state = {};
  };

  if (isLoading) {
    return (
      <div
        style={{
          margin: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          height: '100vh',
        }}
      >
        <Rings color="#D6EF59" />
      </div>
    );
  }

  const emptyState = (text) => {
    return (
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          margin: 'auto',
          height: '50vh',
        }}
      >
        <EmptyState
          useImage="true"
          text={text}
          buttonText="Create new campaign"
          size="small"
        />
      </div>
    );
  };

  const renderCampaignList = () => {
    let filteredResults = Object.entries(campaignList)
      .sort()
      .filter((value) => value[0].toLowerCase().match(regExp));

    return (
      <>
        {filteredResults.length === 0
          ? emptyState('No results matched!')
          : filteredResults.map((item, key) => {
              return (
                <TableDropdown
                  key={`${item[0]}-${key}`}
                  title={item[0]}
                  list={item[1]}
                  draftClientName={draftNavigation.clientName}
                  onHandleResetDraftClientName={setDraftNavigation}
                  refetchCampaigns={fetchAllCampaigns}
                />
              );
            })}
      </>
    );
  };

  return (
    <div className="campaignManager">
      <Card>
        <div className="campaignManager-container">
          <TopNav
            onHandleFilterByStatus={handleFilterByStatus}
            status={filterByStatus}
          />
          <div className="campaignManager-search">
            <SearchBar onHandleSetFilterByClientName={setFilterByClientName} />
          </div>
          <div className="campaignManager-body">
            {Object.keys(campaignList).length === 0
              ? emptyState('No campaigns quite yet!')
              : renderCampaignList()}
          </div>
        </div>
      </Card>
    </div>
  );
}

export default CampaignManager;
