import React, { useState, useEffect } from 'react';
import './styles.scss';
import arrowDown from '../../assets/chevron-down.svg';
import arrowUp from '../../assets/chevron-up.svg';
import check from './assets/check-circle.svg';

function FormDropdown({ title, children, success, isOpen }) {
  const [dropdownActive, setDropdownActive] = useState(false);

  useEffect(() => {
    if (success) {
      setDropdownActive(false);
    }

    if (isOpen) {
      setDropdownActive(true);
    }
  }, [success, isOpen]);

  return (
    <div className="formDropdown">
      <div
        onClick={() => setDropdownActive(!dropdownActive)}
        className="formDropdown-dropdown"
      >
        <div className="formDropdown-dropdown-title">
          {title}
          {success && (
            <img
              className="formDropdown-dropdown-check"
              src={check}
              alt="check Icon"
            />
          )}
        </div>
        {dropdownActive ? (
          <img
            className="formDropdown-dropdown-arrows"
            src={arrowUp}
            alt="chevron-up"
          />
        ) : (
          <img
            className="formDropdown-dropdown-arrows"
            src={arrowDown}
            alt="chevron-down"
          />
        )}
      </div>
      {dropdownActive ? (
        <div className={'formDropdown-content'}>{children}</div>
      ) : (
        <div className={'formDropdown-content-none'}>{children}</div>
      )}
    </div>
  );
}

export default FormDropdown;
