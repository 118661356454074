import { useContext } from 'react';
import { AppContext } from 'context';
import overview from './assets/overview.svg';
import overviewWhite from './assets/overview-white.svg';
import campaigns from './assets/campaigns.svg';
import campaignsWhite from './assets/campaigns-white.svg';
import clients from './assets/clients.svg';
import clientsWhite from './assets/clients-white.svg';
import faq from './assets/faq.svg';
import faqWhite from './assets/faq-white.svg';
import mainLogo from 'feature/assets/se_logo.svg';
import { Link, useLocation } from 'react-router-dom';
import './styles.scss';

const SIDE_TABS = {
  OVERVIEW: 'overview',
  CAMPAIGNS: 'campaignManager',
  ORDERS: 'orders',
  ORDERS_ADMIN: 'ordersAdmin',
  CLIENTS_ADMIN: 'clientsAdmin',
  CAMPAIGN_ADMIN: 'campaignAdmin',
  FAQ: 'faq',
};

const SideNav = () => {
  const { userType } = useContext(AppContext);
  const location = useLocation();
  const getClassWithCurrentSelection = (tab) => {
    const currentTab = location.pathname.split('/').pop();
    return `sidenav-menu-link-container ${
      tab === currentTab ? 'sidenav-menu-link-container-active' : ''
    }`;
  };
  return (
    <div className="sidenav">
      <img
        style={{ height: 66, width: 211 }}
        id="logo"
        src={mainLogo}
        alt="speak-easy-political-logo"
      />
      <div
        id="overviewContainer"
        className={getClassWithCurrentSelection(SIDE_TABS.OVERVIEW)}
      >
        <img id="overview" src={overview} alt="overview" />
        <img id="overviewWhite" src={overviewWhite} alt="overview-white" />
        <Link to={`/dashboard/${SIDE_TABS.OVERVIEW}`}>Overview</Link>
      </div>
      <div
        id="campaignsContainer"
        className={getClassWithCurrentSelection(SIDE_TABS.CAMPAIGNS)}
      >
        <img id="campaigns" src={campaigns} alt="Campaign" />
        <img id="campaignsWhite" src={campaignsWhite} alt="Campaigns-white" />
        <Link to={`/dashboard/${SIDE_TABS.CAMPAIGNS}`}>Campaigns</Link>
      </div>
      {userType === 'users' && (
        <>
          <div
            id="clientsDashboardContainer"
            className={getClassWithCurrentSelection(SIDE_TABS.CLIENTS_ADMIN)}
          >
            <img id="clients" src={clients} alt="Clients" />
            <img id="clientsWhite" src={clientsWhite} alt="Clients White" />
            <Link to={`/dashboard/${SIDE_TABS.CLIENTS_ADMIN}`}>
              Clients admin
            </Link>
          </div>

          <div
            id="ordersDashboardContainer"
            className={getClassWithCurrentSelection(SIDE_TABS.ORDERS_ADMIN)}
          >
            <img id="campaigns" src={campaigns} alt="Campaign" />
            <img
              id="campaignsWhite"
              src={campaignsWhite}
              alt="Campaigns-white"
            />
            <Link to={`/dashboard/${SIDE_TABS.ORDERS_ADMIN}`}>
              Orders admin
            </Link>
          </div>
          <div
            id="campaignsDashboardContainer"
            className={getClassWithCurrentSelection(SIDE_TABS.CAMPAIGN_ADMIN)}
          >
            <img id="campaigns" src={campaigns} alt="Campaign" />
            <img
              id="campaignsWhite"
              src={campaignsWhite}
              alt="Campaigns-white"
            />
            <Link to={`/dashboard/${SIDE_TABS.CAMPAIGN_ADMIN}`}>
              Campaign admin
            </Link>
          </div>
        </>
      )}
      <div
        id="faqContainer"
        className={getClassWithCurrentSelection(SIDE_TABS.FAQ)}
      >
        <img id="faq" src={faq} alt="FAQ" />
        <img id="faqWhite" src={faqWhite} alt="FAQ-white" />
        <Link to={`/dashboard/${SIDE_TABS.FAQ}`}>F.A.Q.</Link>
      </div>
    </div>
  );
};

export default SideNav;
