import React from 'react';
import './styles.scss';
//import EmptyState from './components/emptyState/EmptyState'
import Card from '../../components/card/Card';
import ComingSoon from '../../components/comingSoon/ComingSoon';
import help from './assets/help.svg';

function Audiences() {
  return (
    <div className="clients">
      <Card>
        <div className="emptyStateClients-title">
          <p>Audience</p>
          <img src={help} alt="help-icon" />
        </div>
        <ComingSoon />
      </Card>
    </div>
  );
}

export default Audiences;
