import axios from 'axios';
import { redirect } from 'react-router-dom';
import { auth } from '../firebase';

axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

const instance = axios.create({});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      await auth.signOut();
      redirect('/');
    }
    const detailedErrors = error.response?.data?.errors;
    console.error(error);
    if (Array.isArray(detailedErrors)) {
      detailedErrors.map(console.error);
    }
    throw error;
  }
);
export default instance;
