import React, { useState, useEffect } from 'react';
import './styles.scss';
import nextArrow from './assets/nextArrow.svg';
import Input from '../../../../../../../../components/input/Input';
import TextArea from '../../../../../../../../components/textArea/TextArea';

function BuildTargetAudience({
  onHandlerGoBack,
  goBack,
  onHandleNewTargetAudience,
}) {
  const [name, setName] = useState('');
  const [state, setState] = useState('');
  const [geographicArea, setGeographicArea] = useState('');

  useEffect(() => {
    onHandleNewTargetAudience({
      name: name,
      state: state,
      geographicArea: geographicArea,
    });
  }, [name, state, geographicArea]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="buildTargetAudience">
      <div
        onClick={() => onHandlerGoBack(!goBack)}
        className="buildTargetAudience-go-back"
      >
        <img src={nextArrow} alt="Go back icon" />
        <p>Go Back</p>
      </div>
      <div className="team-message-text-container">
        <p className="team-message-text">
          We partner with TargetSmart to help build your perfect list. Let us
          know{' '}
        </p>
        <p className="team-message-text">
          who you&apos;d like to target below and we&apos;ll help you put
          together a list.
        </p>
      </div>
      <div>
        <div>
          <div id="input-form">
            <Input
              returner={name}
              setter={setName}
              label={'NAME YOUR AUDIENCE *'}
            />
          </div>
          <div id="input-form">
            <Input returner={state} setter={setState} label={'STATE *'} />
          </div>
          <div id="input-form">
            <TextArea
              returner={geographicArea}
              setter={setGeographicArea}
              label={'DESCRIBE YOUR AUDIENCE'}
            />
          </div>
        </div>
      </div>
      <div className="book-call-text">
        <p>Need help building your audience?</p>
        <p>Book a quick call with our team to build your list in real time.</p>
      </div>
      <button
        id="bookCall"
        onClick={() =>
          window.open(
            'https://meetings.hubspot.com/hello587/the-media-studio',
            '_blank'
          )
        }
        className="book-call"
      >
        Book a call
      </button>
    </div>
  );
}

export default BuildTargetAudience;
