import React from 'react';
import './styles.scss';
import campaignWhite from './assets/campaign.svg';
import clientWhite from './assets/clients-white.svg';

function InfoCard({ number, text, icon, marginDirection }) {
  return (
    <div className={`infoCard infoCard-margin-${marginDirection}`}>
      <p className="infoCard-number">{number}</p>
      <p className="infoCard-text">{text}</p>
      <img
        src={icon === 'client' ? clientWhite : campaignWhite}
        alt="info card icon"
      />
    </div>
  );
}

export default InfoCard;
