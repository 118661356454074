import React, { useState, useContext } from 'react';
import './styles.scss';
import _ from 'lodash';
import Input from '../../../../../../components/input/Input';
import { CampaignContext } from '../../../../Campaign';

function CampaignName({
  onHandleSuccess,
  onHandleNextSectionOpen,
  onHandleCampaignNameIsOpen,
}) {
  const { campaignDetails, setCampaignDetails } = useContext(CampaignContext);
  const [campaignName, setCampaignName] = useState(
    campaignDetails.campaignName
  );
  const [onSuccess, setOnSucces] = useState(false);
  const handlerCampaignName = () => {
    setCampaignDetails({ ...campaignDetails, campaignName });
    setOnSucces(true);
    onHandleCampaignNameIsOpen(false);
    onHandleNextSectionOpen(true);
    onHandleSuccess(true);
  };

  const handleState = (value) => {
    onHandleSuccess(false);
    setOnSucces(false);
    setCampaignName(value);
  };
  return (
    <div className="campaignName">
      <p>What do you want to title this ad set?</p>
      <Input success={onSuccess} setter={handleState} returner={campaignName} />
      <button
        className={`button  button--${
          _.isEmpty(campaignName) ? 'disabled' : 'active'
        }`}
        onClick={() => handlerCampaignName(campaignDetails)}
      >
        Save
      </button>
    </div>
  );
}

export default CampaignName;
