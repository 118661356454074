import { createClients } from '../clients/clientService';
import { createAudiences } from '../audience/audienceService';
import _ from 'lodash';
import instance from '../axiosConfig';
import {
  serializeCampaignDetailsToFormData,
  buildCampaignDetailsFromFetchedCampaign,
} from '../../utils/campaignUtils';
const urlAsUser = process.env.REACT_APP_CAMPAIGNS;
const urlAsAdmin = process.env.REACT_APP_CAMPAIGNS_AS_ADMIN;

function getBase64Image(img) {
  let newImg = new Image(img);
  var canvas = document.createElement('canvas');
  canvas.width = newImg.width;
  canvas.height = newImg.height;

  var ctx = canvas.getContext('2d');
  ctx.drawImage(newImg, 0, 0);

  var dataURL = canvas.toDataURL('image/png');
  return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
}

export const createCampaigns = async (
  campaign,
  url = urlAsUser,
  setLoading
) => {
  if (!campaign.clientId) {
    const newClient = await createClients(campaign.clientName);
    campaign.clientId = newClient.id;
  }

  if (!campaign.targetAudience?.id) {
    const newAudience = await createAudiences(campaign.targetAudience);
    campaign.targetAudience.id = newAudience.id;
  }
  let formData = serializeCampaignDetailsToFormData(campaign);
  const result = instance
    .post(url, formData, {
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setLoading(percentCompleted);
      },
    })
    .then((response) => {
      let list = [];

      campaign['campaignID'] = response.data.data.id;
      campaign['clientID'] = response.data.data.attributes.client.id;
      campaign.banners = (campaign.banners || []).map((value) => {
        let imgData = getBase64Image(value);
        return imgData;
      });
      list.push(campaign);
      if (!_.isEmpty(JSON.parse(localStorage.getItem('campaignList')))) {
        let savedList = JSON.parse(localStorage.getItem('campaignList'));
        savedList.push(campaign);
        localStorage.setItem('campaignList', JSON.stringify(savedList));
      } else {
        localStorage.setItem('campaignList', JSON.stringify(list));
      }

      return response;
    });

  return result;
};

export const createDrafts = async (campaign, url = urlAsUser, setLoading) => {
  if (!campaign.clientId) {
    const newClient = await createClients(campaign.clientName);
    campaign.clientId = newClient.id;
  }

  if (!campaign.targetAudience?.id) {
    const newAudience = await createAudiences(campaign.targetAudience);
    campaign.targetAudience = { id: newAudience.id };
  }

  campaign.draft = true;

  let formData = serializeCampaignDetailsToFormData(campaign);

  const result = await instance.post(url, formData, {
    onUploadProgress: (progressEvent) => {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      setLoading(percentCompleted);
    },
  });

  return result.data.data;
};

export const getAllCampaigns = (status = '', url = urlAsUser) => {
  return instance
    .get(`${url}?status=${status}`, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(function (response) {
      return response.data.data;
    });
};

export const getAllCampaignsInCart = (url = urlAsUser) => {
  return instance
    .get(`${url}?in_cart=true`, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(function (response) {
      if (response.status !== 200) {
        throw new Error('Failed to fetch in_cart campaigns.');
      }
      const fetchedCampaigns = response.data.data;
      return fetchedCampaigns.map(buildCampaignDetailsFromFetchedCampaign);
    });
};

export const getAllCampaignsAsAdmin = (status = '', url = urlAsAdmin) => {
  return instance
    .get(`${url}?status=${status}`, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(function (response) {
      return response.data.data;
    });
};

export const getCampaignByIdAsAdmin = (campaignId, url = urlAsAdmin) => {
  return instance
    .get(`${url}/${campaignId}`, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(function (response) {
      // handle success
      const link = document.createElement('a');
      link.href = response.data.data.attributes.media_files_zip;
      link.setAttribute('download', `media`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
};

export const getCampaignById = (campaignId, url = urlAsUser) => {
  return instance
    .get(`${url}/${campaignId}`, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(function (response) {
      // handle success
      return response.data.data;
    });
};

export const editCampaignByIdAsAdmin = (campaign, url = urlAsAdmin) => {
  let orderFormData = new FormData();
  orderFormData.append('id', campaign.id);
  orderFormData.append('campaign[status]', campaign.status);

  return instance
    .put(`${url}/${campaign.id}`, orderFormData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(function (response) {
      // handle success
      return response.data.data;
    });
};

export const editCampaign = (campaign, url = urlAsUser, setLoading) => {
  campaign.draft = campaign.status === 'draft';

  let formData = serializeCampaignDetailsToFormData(campaign);

  return instance
    .put(`${url}/${campaign.id}`, formData, {
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setLoading(percentCompleted);
      },
    })
    .then(function (response) {
      let list = [];
      campaign['campaignID'] = response.data.data.id;
      campaign['clientID'] = response.data.data.attributes.client.id;
      campaign.banners = (campaign.banners || []).map((value) => {
        let imgData = getBase64Image(value);
        return imgData;
      });
      list.push(campaign);
      if (!_.isEmpty(JSON.parse(localStorage.getItem('campaignList')))) {
        let savedList = JSON.parse(localStorage.getItem('campaignList'));
        savedList.push(campaign);
        localStorage.setItem('campaignList', JSON.stringify(savedList));
      } else {
        localStorage.setItem('campaignList', JSON.stringify(list));
      }

      return response;
    });
};

export const editCampaignStatus = (campaign, url = urlAsUser) => {
  const formData = new FormData();
  formData.append('id', campaign.id);
  formData.append('campaign[status]', campaign.status);

  return instance
    .put(`${url}/${campaign.id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(function (response) {
      return response;
    });
};

export const savePendingChangesToExistingCampaign = (
  campaign,
  pendingChanges
) => {
  campaign.pendingChanges = pendingChanges;
  return editCampaign(campaign);
};

export const executePendingCampaignChanges = (campaign) => {
  const { pendingChanges } = campaign;
  _.merge(campaign, pendingChanges);
  campaign.pendingChanges = undefined;

  return editCampaign(campaign);
};
