import React, { useState, useEffect } from 'react';
import './styles.scss';
import _ from 'lodash';
import { Rings } from 'react-loader-spinner';
import { createOrders } from '../../services/orders/ordersService';
import {
  executePendingCampaignChanges,
  getAllCampaignsInCart,
} from '../../services/campaign/campaignService';
import Card from '../../components/card/Card';
import CardBody from '../../components/card/components/cardBody/CardBody';
import EmptyState from './components/emptyState/EmptyState';
import FullState from './components/fullState/FullState';
import OrderPlaced from './components/orderPlaced/OrderPlaced';
import Button from '../../components/button/Button';
import CardTitle from '../../components/card/components/cardTitle/CardTitle';
import DetailsAndSummaryLayout from '../../components/detailsAndSummaryLayout/DetailsAndSummaryLayout';
import Summary from './components/summary/Summary';

function Cart() {
  const [isLoading, setIsLoading] = useState(true);
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [campaignsInCart, setCampaignsInCart] = useState([]);

  const handlePlaceOrders = () => {
    const [existingCampaignsWithPendingChanges, newCampaigns] = [[], []];
    campaignsInCart.forEach((campaign) => {
      if (!_.isEmpty(campaign.pendingChanges)) {
        existingCampaignsWithPendingChanges.push(campaign);
      } else {
        newCampaigns.push(campaign);
      }
    });

    Promise.all([
      processExistingCampaignsWithPendingChanges(
        existingCampaignsWithPendingChanges
      ),
      processNewCampaigns(newCampaigns),
    ]).then(() => {
      localStorage.removeItem('campaignList');
      // TODO: Add logic for partial failure mode (i.e. some requests failed).
      setOrderPlaced(true);
    });
  };

  useEffect(() => {
    getAllCampaignsInCart().then((campaigns) => {
      setCampaignsInCart(campaigns);
      setIsLoading(false);
    });
  }, []);

  if (orderPlaced) {
    return (
      <div className="cart">
        <Card size={'full'}>
          <CardTitle>Order Placed</CardTitle>
          <CardBody>
            <OrderPlaced />
          </CardBody>
        </Card>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div
        style={{
          margin: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          height: '100vh',
        }}
      >
        <Rings color="#D6EF59" />
      </div>
    );
  }

  return (
    <div className="cart">
      <DetailsAndSummaryLayout
        detailsTitle="Items in cart"
        detailsBody={
          _.isEmpty(campaignsInCart) || isLoading ? (
            <EmptyState />
          ) : (
            <FullState ordersList={campaignsInCart} />
          )
        }
        summaryTitle="Order Summary"
        summaryBody={<Summary campaignsInCart={campaignsInCart} />}
        actions={
          <Button
            disabled={campaignsInCart.length === 0 ? true : false}
            size={'big'}
            useIcon={false}
            width="100%"
            onClick={() => campaignsInCart.length > 0 && handlePlaceOrders()}
          >
            Place order
          </Button>
        }
      />
    </div>
  );
}

function processExistingCampaignsWithPendingChanges(campaigns) {
  const applyPendingChangesRequestPromises = campaigns.map((campaign) =>
    executePendingCampaignChanges(campaign)
  );
  return Promise.allSettled(applyPendingChangesRequestPromises);
}

async function processNewCampaigns(campaigns) {
  const ordersToBePlaced = campaigns.map((campaign) => {
    return {
      client_id: campaign.clientId,
      campaign_id: campaign.id,
    };
  });
  return createOrders(ordersToBePlaced).then((orderRequestResults) => {
    orderRequestResults.forEach((result) => {
      if (result.status === 'rejected') console.error(result.reason);
    });
    return orderRequestResults;
  });
}

export default Cart;
