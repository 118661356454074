import React, { useState, useContext, useMemo, useEffect } from 'react';
import Sumary from '../sumary/Sumary';
import AddOrDraft from '../addOrDraft/AddOrDraft';

import FormDropdown from './components/FormDropdown/FormDropdown';
import ClientName from './components/clientName/ClientName';
import CampaignName from './components/campaignName/CampaignName';
import UploadBanners from './components/uploadBanners/UploadBanners';
import AddUrl from './components/addUrl/AddUrl';
import TargetAudience from './components/targetAudience/TargetAudience';
import FlightDates from './components/flightDates/FlightDates';
import DetermineBudget from './components/determineBudget/DetermineBudget';
import { CampaignContext } from '../../Campaign';
import DetailsAndSummaryLayout from '../../../../components/detailsAndSummaryLayout/DetailsAndSummaryLayout';
import { useConfirmExit } from 'hooks/useConfirmExit';

function EditDraftCampaign() {
  const { campaignDetails, setCampaignDetails } = useContext(CampaignContext);
  const [clientNameSuccess, setClientNameSuccess] = useState(false);
  const [isClientNameOpen, setIsClientOpen] = useState(true);
  const [campaignNameSuccess, setCampaignNameSuccess] = useState(false);
  const [isCampaignNameSuccessOpen, setCampaignNameSuccessOpen] =
    useState(false);
  const [bannersSuccess, setBannersSuccess] = useState(false);
  const [isBannerOpen, setIsBannerOpen] = useState(false);
  const [urlSuccess, setUrlSuccess] = useState(false);
  const [isUrlOpen, setIsUrlOpen] = useState(false);
  const [targetAudienceSuccess, setTargetAudienceSuccess] = useState(false);
  const [isAudienceOpen, setIsAudienceOpen] = useState(false);
  const [flightDatesSuccess, setflightDatesSuccess] = useState(false);
  const [isDatesOpen, setIsDateOpen] = useState(false);
  const [budgetSuccess, setBudgetSuccess] = useState(false);
  const [isBudgetOpen, setIsBudgetOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isComplete, setIsComplete] = useState(false);

  useConfirmExit(showDialog);

  const isDraftStarted = useMemo(() => {
    return (
      clientNameSuccess ||
      campaignNameSuccess ||
      bannersSuccess ||
      urlSuccess ||
      targetAudienceSuccess ||
      flightDatesSuccess ||
      budgetSuccess
    );
  }, [
    clientNameSuccess,
    campaignNameSuccess,
    bannersSuccess,
    urlSuccess,
    targetAudienceSuccess,
    flightDatesSuccess,
    budgetSuccess,
  ]);

  useEffect(() => {
    setShowDialog(isDraftStarted && !isComplete);
  }, [isDraftStarted, isComplete]);

  return (
    <DetailsAndSummaryLayout
      detailsTitle={`Let's launch some ads!`}
      detailsBody={
        <>
          <FormDropdown
            isOpen={isClientNameOpen}
            success={clientNameSuccess}
            title={'Create Client'}
          >
            <ClientName
              onHandleSuccess={setClientNameSuccess}
              onHandleClienNameIsOpen={setIsClientOpen}
              onHandleNextSectionOpen={setCampaignNameSuccessOpen}
            />
          </FormDropdown>

          <FormDropdown
            isOpen={isCampaignNameSuccessOpen}
            success={campaignNameSuccess}
            title={'Name campaign'}
          >
            <CampaignName
              onHandleSuccess={setCampaignNameSuccess}
              onHandleCampaignNameIsOpen={setCampaignNameSuccessOpen}
              onHandleNextSectionOpen={setIsBannerOpen}
            />
          </FormDropdown>

          <FormDropdown
            isOpen={isBannerOpen}
            success={bannersSuccess}
            title={
              campaignDetails.type === 'banner'
                ? 'Upload banners'
                : 'Upload video'
            }
          >
            <UploadBanners
              onHandleSuccess={setBannersSuccess}
              onHandleBannerIsOpen={setIsBannerOpen}
              onHandleNextSectionOpen={setIsUrlOpen}
            />
          </FormDropdown>

          <FormDropdown
            isOpen={isUrlOpen}
            success={urlSuccess}
            title={'Add Landing Page'}
          >
            <AddUrl
              onHandleSuccess={setUrlSuccess}
              onHandleUrlOpen={setIsUrlOpen}
              onHandleNextSectionOpen={setIsAudienceOpen}
            />
          </FormDropdown>

          <FormDropdown
            isOpen={isAudienceOpen}
            success={targetAudienceSuccess}
            title={'Target audience'}
          >
            <TargetAudience
              onHandleSuccess={setTargetAudienceSuccess}
              onHandleIsAudienceOpen={setIsAudienceOpen}
              onHandleNextSectionOpen={setIsDateOpen}
            />
          </FormDropdown>

          <FormDropdown
            isOpen={isDatesOpen}
            success={flightDatesSuccess}
            title={'Select flight dates'}
          >
            <FlightDates
              onHandleSuccess={setflightDatesSuccess}
              campaignDetails={campaignDetails}
              onHandleSetFlightDates={(updatedCampaignDetails) =>
                setCampaignDetails({ ...updatedCampaignDetails })
              }
              onHandleIsDateOpen={(isDateOpen) => {
                setIsDateOpen(isDateOpen);
                setIsBudgetOpen(!isBudgetOpen);
              }}
            />
          </FormDropdown>

          <FormDropdown
            isOpen={isBudgetOpen}
            success={budgetSuccess}
            title={'Determine budget'}
          >
            <DetermineBudget
              onHandleSuccess={setBudgetSuccess}
              onHandleIsBudgetOpen={setIsBudgetOpen}
            />
          </FormDropdown>
        </>
      }
      summaryTitle={'Campaign Summary'}
      summaryBody={<Sumary />}
      actions={<AddOrDraft onHandleIsComplete={setIsComplete} />}
    />
  );
}

export default EditDraftCampaign;
