import React, { useState } from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/card/Card';
import Input from '../../components/input/Input';
import Button from '../../components/button/Button';

import { useAuth } from '../../context/AuthContext';

import Logo from '../assets/se_logo.svg';
import MailSentSuccess from './components/mailSentSuccess/MailSentSuccess';

function RecoverPassword() {
  const { sendPasswordReset } = useAuth();
  const [email, setEmail] = useState('');
  const [mailSent, setMailSent] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const handleSentRecoveryPasswordMail = (e) => {
    e.preventDefault();
    e.stopPropagation();

    sendPasswordReset(email)
      .then((resp) => {
        console.log(resp);
        setMailSent(true);
      })
      .catch((err) => setError(err));
  };

  return (
    <div className="recoverPassword">
      <Card>
        <div className="recoverPassword-form">
          <img
            style={{ width: 257, height: 80, marginBottom: 64 }}
            src={Logo}
            alt="SpeakEasy Studios logo"
          />
          {!mailSent ? (
            <form onSubmit={handleSentRecoveryPasswordMail}>
              <p id="recoveryText">Forgot your password?</p>
              <p id="recoveryText">We&apos;ll help you get a new one!</p>

              <div className="recoverPassword-form-input-container">
                <Input
                  value={email}
                  setter={setEmail}
                  label={'Email'}
                  type={'email'}
                  fail={error}
                />
              </div>

              <div className="recoverPassword-form-access">
                <Button
                  disabled={email.length === 0 ? true : false}
                  size={'big'}
                >
                  Send password reset email
                </Button>
              </div>

              <div className="recoverPassword-form-bottom">
                <p onClick={() => navigate('/')} id="bottomText">
                  Return to Login
                </p>
              </div>
            </form>
          ) : (
            <MailSentSuccess />
          )}
        </div>
      </Card>
    </div>
  );
}

export default RecoverPassword;
