import React, { useState, useContext } from 'react';
import './styles.scss';
import _ from 'lodash';
import Input from '../../../../../../components/input/Input';
import { CampaignContext } from '../../../../Campaign';
import {
  formatBudget,
  convertBudgetToNumber,
  formatNumberToCurrency,
} from '../../../../../.././utils/currencyUtils';

function DetermineBudget({ onHandleSuccess, onHandleIsBudgetOpen }) {
  const { campaignDetails, setCampaignDetails } = useContext(CampaignContext);
  const [budget, setBudget] = useState(
    convertBudgetToNumber(campaignDetails.budget)
  );
  const [onSuccess, setOnSucces] = useState(false);
  const [formattedBudgetString, setFormattedBudgetString] = useState(
    formatBudget(campaignDetails.budget)
  );

  const handlerBudget = () => {
    setFormattedBudgetString(formatNumberToCurrency(formattedBudgetString));
    setCampaignDetails({ ...campaignDetails, budget });
    setOnSucces(true);
    onHandleSuccess(true);
    onHandleIsBudgetOpen(false);
  };

  const handleState = (value) => {
    onHandleSuccess(false);
    setOnSucces(false);
    setBudget(convertBudgetToNumber(value));
    const formattedBudget = formatBudget(value);
    setFormattedBudgetString(formattedBudget);
  };

  return (
    <div className="determineBudget">
      <p>What is your budget for this program?</p>
      <Input
        success={onSuccess}
        setter={handleState}
        returner={formattedBudgetString}
      />
      <button
        className={`button  button--${
          _.isEmpty(budget.toString()) ? 'disabled' : 'active'
        }`}
        onClick={() => handlerBudget(campaignDetails)}
      >
        Save
      </button>
    </div>
  );
}

export default DetermineBudget;
