import SideNav from 'components/sideNav/SideNav';
import TopNav from 'components/topNav/TopNav';
import { Outlet } from 'react-router-dom';
import styles from './Dashboard.module.scss';

const Dashboard = () => {
  return (
    <div>
      <SideNav />
      <div className={styles.rightColumn}>
        <TopNav />
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
