import React, { useContext } from 'react';
import moment from 'moment';

import './styles.scss';
import { CampaignContext } from '../../Campaign';
import { formatNumberToCurrency } from '../../../../utils/currencyUtils';

function Sumary() {
  const { campaignDetails } = useContext(CampaignContext);
  let from = campaignDetails.flightDates?.from;
  let to = campaignDetails.flightDates?.to;
  let campaignType = campaignDetails.type;
  return (
    <>
      <p className="sumary-concept">
        Format:{' '}
        <span>
          {campaignDetails.type}{' '}
          {campaignType === 'video' ? `- ${campaignDetails.videoType}` : ''}
        </span>
      </p>
      <p className="sumary-concept">Client: {campaignDetails.clientName}</p>
      <p className="sumary-concept">Name: {campaignDetails.campaignName}</p>
      <p className="sumary-concept">
        {' '}
        {campaignType === 'video' ? 'Video length:' : 'Number of Banners:'}{' '}
        {campaignType === 'banner'
          ? campaignDetails.banners?.length
          : Math.trunc(campaignDetails.videoDuration) || 0}{' '}
        {campaignType === 'video' ? 'sec' : ''}{' '}
      </p>
      <p className="sumary-concept">Landing Page: {campaignDetails.url}</p>
      <p className="sumary-concept">
        Audience: {campaignDetails.targetAudience?.name}
      </p>
      <p className="sumary-concept">
        Flight dates:{' '}
        {from === null || from === undefined || from.length === 0
          ? ''
          : moment(from).format('MM/DD/YY')}{' '}
        -{' '}
        {from === null || to === undefined || to.length === 0
          ? ''
          : moment(to).format('MM/DD/YY')}
      </p>
      <p className="sumary-concept">
        Budget: {formatNumberToCurrency(campaignDetails.budget)}
      </p>
    </>
  );
}

export default Sumary;
