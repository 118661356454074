import React from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';

import Button from '../../../../components/button/Button';

import emptyStateImage from './assets/vector_empty.svg';

function EmptyState({ useImage, text, buttonText, size }) {
  const navigate = useNavigate();
  return (
    <div className={`emptyState-${size}`}>
      {useImage && <img src={emptyStateImage} alt="Empty state" />}
      <p className={`emptyState-${size}-text`}>{text}</p>
      <div onClick={() => navigate('/dashboard/campaigns')}>
        <Button size={'big'} useIcon>
          {buttonText}
        </Button>
      </div>
    </div>
  );
}

export default EmptyState;
