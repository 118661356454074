import React, { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AppContext } from '../../context';

const ProtectedRoute = ({ children }) => {
  let { authenticated } = useContext(AppContext);
  const location = useLocation();

  if (!authenticated) {
    return <Navigate replace to="/" state={{ path: location.pathname }} />;
  }
  return children;
};

export default ProtectedRoute;
