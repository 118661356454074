import { useState } from 'react';
import Button from '../../../../../../components/button/Button';
import styles from './AddFlightDates.module.scss';
import { formatDateRange } from '../../../../../../utils/dateUtils';
import DayPicker, { DateUtils } from 'react-day-picker';

const AddFlightDates = ({
  onSaveFlightDates,
  onCancel,
  currentFlightDates,
  newFlightDates,
}) => {
  const defaultSelected = {
    from: new Date(newFlightDates?.from || currentFlightDates.from),
    to: new Date(newFlightDates?.to || currentFlightDates.to),
  };
  const [range, setRange] = useState(defaultSelected);
  const { from, to } = range;
  const today = new Date();
  const validStartDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + 4
  );
  const modifiers = {
    start: range.from,
    end: range.to,
  };

  const handleDayClick = (day, { selected }) => {
    if (day < validStartDate) {
      return;
    }
    const newRange = DateUtils.addDayToRange(day, range);
    setRange(newRange);
  };

  const handleReset = (e) => {
    e.preventDefault();
    setRange({ from: undefined, to: undefined });
  };

  return (
    <form
      className={styles.container}
      onReset={onCancel}
      onSubmit={(e) => {
        e.preventDefault();
        onSaveFlightDates(range);
      }}
    >
      <div className={styles.currentFlightDates}>
        <label htmlFor={'currentFlightDates'}>Current run dates:</label>
        <input
          readOnly
          disabled
          type="text"
          value={formatDateRange(
            currentFlightDates.from,
            currentFlightDates.to
          )}
        />
      </div>
      <p>Select new flight dates:</p>
      <DayPicker
        className="Selectable"
        numberOfMonths={2}
        selectedDays={[from, { from, to }]}
        modifiers={modifiers}
        onDayClick={handleDayClick}
        disabledDays={[{ before: validStartDate }]}
      />
      <div className={styles.buttons}>
        {from && (
          <Button
            type="secondary"
            size="big"
            asFormInput={false}
            onClick={handleReset}
          >
            Clear range
          </Button>
        )}
        <Button type="secondary" asFormInput size="big">
          Cancel
        </Button>
        <Button asFormInput size="big">
          Save
        </Button>
      </div>
    </form>
  );
};

export default AddFlightDates;
