import React from 'react';
import './styles.scss';

function Checkbox({ disabled, checked, onChange }) {
  return (
    <input
      disabled={disabled}
      type="checkbox"
      checked={checked}
      onChange={onChange}
    />
  );
}

export default Checkbox;
