import { useState, useEffect } from 'react';

import { handleFirebaseLogin } from '../services/user/userServices';
import instance from '../services/axiosConfig';

const useFirebaseAuthentication = (auth) => {
  const [{ apiUser, userType }, setAuthUser] = useState({});
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const unlisten = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        instance.defaults.headers.Authorization = `Bearer ${authUser.accessToken}`;
        handleFirebaseLogin()
          .then((user) => {
            setAuthUser({
              apiUser: user.attributes,
              userType: user.type,
            });
            setAuthenticated(true);
          })
          .catch((err) => {
            if (err) auth.signOut();
          });
      } else {
        setAuthUser({});
        setAuthenticated(false);
      }
    });
    return () => {
      unlisten();
    };
  }, [auth]);

  return { authenticated, apiUser, userType };
};

export default useFirebaseAuthentication;
