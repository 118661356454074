import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import arrowDown from './assets/chevron-down.svg';
import arrowUp from './assets/chevron-up.svg';
import { isCampaignEditable } from '../../../../utils/campaignUtils';
import ActionsDropdown from './components/actionsDropdown/ActionsDropdown';
import { formatNumberToCurrency } from '../../../../utils/currencyUtils';
import styles from './TableDropdown.module.scss';
import clsx from 'clsx';

const columnNames = [
  'campaignName',
  'dates',
  'type',
  'budget',
  'status',
  'actions',
];

function TableDropdown({
  title,
  list,
  draftClientName,
  onHandleResetDraftClientName,
  refetchCampaigns,
}) {
  const [searchParams] = useSearchParams();
  const [sortColumn, setSortColumn] = useState('status');
  const [isCurrentColumnSortReversed, setIsCurrentColumnSortReversed] =
    useState(false);

  const getSortedCampaignList = useCallback(
    (columnName, reverseSort) => {
      switch (columnName) {
        case 'status': {
          list.sort((itemA, itemB) => {
            return statusOrderMap[itemA.status] - statusOrderMap[itemB.status];
          });
          break;
        }
        case 'budget': {
          list.sort((itemA, itemB) => {
            return itemB.budget - itemA.budget;
          });
          break;
        }
        case 'campaignName': {
          list.sort((itemA, itemB) => {
            return itemA.campaignName.localeCompare(itemB.campaignName);
          });
          break;
        }
        case 'type': {
          list.sort((itemA, itemB) => {
            return itemA.type.localeCompare(itemB.type);
          });
          break;
        }
        default:
          break;
      }

      if (reverseSort) {
        list.reverse();
      }

      return [...list];
    },
    [list]
  );

  const [sortedCampaignList, setSortedCampaignList] = useState(
    getSortedCampaignList(sortColumn)
  );
  const [dropdownActive, setDropdownActive] = useState(
    !!searchParams.get('expand')
  );
  const navigate = useNavigate();
  const location = useLocation();

  const handleDropdown = () => {
    setDropdownActive(!dropdownActive);
    onHandleResetDraftClientName({});
    location.state = {};
  };

  const statusDictionary = {
    'pre-launch': 'starting soon',
    live: 'active',
    'pause-requested': 'pause requested',
    'start-requested': 'restart requested',
    ended: 'ended',
    paused: 'paused',
    canceled: 'canceled',
    draft: 'draft',
  };

  const sortBy = useCallback(
    (columnName, reverse = false) => {
      setSortedCampaignList(getSortedCampaignList(columnName, reverse));
    },
    [getSortedCampaignList]
  );

  useEffect(() => {
    sortBy(sortColumn, isCurrentColumnSortReversed);
  }, [sortBy, sortColumn, isCurrentColumnSortReversed]);

  function getColumnHeaderClassName(columnName, isSortable) {
    const classes = [styles[columnName]];
    if (isSortable) {
      classes.push(styles.sortable);
      if (sortColumn === columnName) {
        classes.push(styles.sorted);
        if (isCurrentColumnSortReversed) {
          classes.push(styles.reverse);
        }
      }
    }
    if (columnName === 'actions') classes.push(styles.actions);
    return clsx(...classes);
  }

  function handleColumnSort(columnName) {
    setSortColumn((prevSortedColumn) => {
      if (prevSortedColumn === columnName) {
        setIsCurrentColumnSortReversed(!isCurrentColumnSortReversed);
      } else {
        setIsCurrentColumnSortReversed(false);
      }
      return columnName;
    });
  }

  return (
    <div className={styles.container}>
      <div onClick={handleDropdown} className={styles.dropdown}>
        {dropdownActive || title === draftClientName ? (
          <img className={styles.arrow} src={arrowUp} alt="chevron-up" />
        ) : (
          <img className={styles.arrow} src={arrowDown} alt="chevron-down" />
        )}
        <p className={styles.title}>
          {title}
          &nbsp;
          <span className={styles.numCampaigns}>
            ({sortedCampaignList.length} campaign
            {sortedCampaignList.length > 1 ? 's' : ''})
          </span>
        </p>
      </div>
      <div
        className={clsx(styles.content, {
          [styles.none]: !(dropdownActive || title === draftClientName),
        })}
      >
        <table>
          <thead>
            <tr>
              {columnNames.map((columnName) => {
                const columnConfig = columnHeaderConfigs[columnName];
                return (
                  <th
                    key={columnName}
                    className={getColumnHeaderClassName(
                      columnName,
                      columnConfig.isSortable
                    )}
                    onClick={
                      columnConfig.isSortable
                        ? () => handleColumnSort(columnName)
                        : undefined
                    }
                  >
                    {columnConfig.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {sortedCampaignList.map((campaign, key) => {
              return (
                <tr key={`${title}-${key}`}>
                  <td
                    className={clsx(styles[columnNames[0]], {
                      [styles.editable]: isCampaignEditable(campaign),
                    })}
                    onClick={
                      isCampaignEditable(campaign)
                        ? () => {
                            navigate(`/dashboard/campaigns/${campaign.id}`);
                          }
                        : undefined
                    }
                  >
                    {campaign.campaignName}
                  </td>
                  <td className={styles[columnNames[1]]}>
                    {formatDateRange(
                      campaign.flightDates?.from,
                      campaign.flightDates?.to
                    )}
                  </td>
                  <td className={styles[columnNames[2]]}>
                    {campaign.type === 'banner'
                      ? campaign.type
                      : campaign.videoType === 'ott'
                      ? 'OTT'
                      : campaign.videoType}
                  </td>
                  <td className={styles[columnNames[3]]}>
                    {formatNumberToCurrency(campaign.budget)}
                  </td>
                  <td className={styles[columnNames[4]]}>
                    <div
                      className={clsx(styles.pill, styles[campaign.status])}
                    ></div>
                    {statusDictionary[campaign.status]}
                  </td>
                  <td className={styles[columnNames[5]]}>
                    <ActionsDropdown
                      campaignStatus={campaign.status}
                      campaignId={campaign.id}
                      refetchCampaigns={refetchCampaigns}
                      isCampaignEditable={isCampaignEditable(campaign)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const statusOrderMap = [
  'live',
  'pre-launch',
  'start-requested',
  'pause-requested',
  'paused',
  'draft',
  'ended',
  'canceled',
].reduce((map, status, currentIndex) => {
  map[status] = currentIndex;
  return map;
}, {});

const columnHeaderConfigs = {
  campaignName: {
    title: 'Campaign',
    isSortable: true,
  },
  dates: {
    title: 'Flight Dates',
    isSortable: false,
  },
  type: {
    title: 'Type',
    isSortable: true,
  },
  budget: {
    title: 'Budget',
    isSortable: true,
  },
  status: {
    title: 'Status',
    isSortable: true,
  },
  actions: {
    title: 'Actions',
    isSortable: false,
  },
};

function formatDateRange(startDateString, endDateString) {
  const startDate = moment(startDateString);
  const endDate = moment(endDateString);

  const format = 'MM/DD/YY';
  const [formattedStartDate, formattedEndDate] = [startDate, endDate].map(
    (date) => {
      return date.isValid() ? date.format(format) : '';
    }
  );
  if (!formattedStartDate && !formattedEndDate) {
    return '';
  }
  return `${formattedStartDate} - ${formattedEndDate}`;
}

export default TableDropdown;
