import instance from '../axiosConfig';
const URL = process.env.REACT_APP_CLIENTS;

export const createClients = (client, url = URL) => {
  return instance
    .post(url, {
      client: {
        name: client,
      },
    })
    .then((response) => {
      return response.data.data;
    });
};

export const getClientById = () => {
  instance().get('/user?ID=12345');
};

export const getAllClients = (url = URL) => {
  return instance.get(url).then(function (response) {
    return response.data;
  });
};
