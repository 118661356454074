import React from 'react';
import './styles.scss';
import comingSoonImg from './assets/vector_underConstruction.png';

function ComingSoon() {
  return (
    <div className="comingSoon">
      <img src={comingSoonImg} alt="Coming soon section" />
      <p className="primaryText">
        This section is currently under construction
      </p>

      <div>
        <p className="secondaryText">
          Our team looks forward to sharing it with beta
        </p>
        <p className="secondaryText">
          users when our Audience Manger launches.
        </p>
      </div>

      <div>
        <p className="secondaryText">
          If there is anything we can do to support your audience targeting in
          the meantime,
        </p>
        <p className="secondaryText">
          please reach out to studio@speakeasypolitical.com.
        </p>
      </div>
    </div>
  );
}

export default ComingSoon;
