import React, { useState, useContext } from 'react';
import './styles.scss';
import _ from 'lodash';
import Input from '../../../../../../components/input/Input';
import { CampaignContext } from '../../../../Campaign';

function AddUrl({ onHandleSuccess, onHandleUrlOpen, onHandleNextSectionOpen }) {
  const { campaignDetails, setCampaignDetails } = useContext(CampaignContext);
  const [url, setUrl] = useState(campaignDetails.url);
  const [onSuccess, setOnSucces] = useState(false);
  const handlerUrl = () => {
    setCampaignDetails({ ...campaignDetails, url });
    setOnSucces(true);
    onHandleSuccess(true);
    onHandleUrlOpen(false);
    onHandleNextSectionOpen(true);
  };

  const handleState = (value) => {
    onHandleSuccess(false);
    setOnSucces(false);
    setUrl(value);
  };
  return (
    <div className="addUrl">
      <div className="addUrl-text-container">
        <p>Someone clicks on your ad. Where should we send them?</p>
        <p>Hint: For best results, URLs should start with an ”https://”</p>
      </div>
      <Input success={onSuccess} setter={handleState} returner={url} />
      <button
        className={`button  button--${_.isEmpty(url) ? 'disabled' : 'active'}`}
        onClick={() => handlerUrl(campaignDetails)}
      >
        Save
      </button>
    </div>
  );
}

export default AddUrl;
