import React, { useContext } from 'react';
import './styles.scss';
import upload from './assets/upload.svg';
import uploadHover from './assets/uploadHover.svg';
import { CampaignContext } from '../../../../feature/campaign/Campaign';

function BannerSelector() {
  const { campaignDetails } = useContext(CampaignContext);
  return (
    <div className="bannerSelector">
      <img id="blackIcon" src={upload} alt="Upload banner icon" />
      <img id="whiteIcon" src={uploadHover} alt="White upload banner icon" />
      <p className="bannerSelector-title">
        {campaignDetails.type === 'banner' ? 'Upload Banners' : 'Upload video'}
      </p>
      {campaignDetails.type === 'banner' ? (
        <p className="bannerSelector-placeholder">Drag and drop or</p>
      ) : (
        ''
      )}
      {campaignDetails.type === 'banner' ? (
        <p className="bannerSelector-placeholder">Select from desktop</p>
      ) : (
        ''
      )}
    </div>
  );
}

export default BannerSelector;
