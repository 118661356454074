import React from 'react';
import './styles.scss';
import Card from '../../components/card/Card';
import SearchBar from '../../components/searchBar/SearchBar';
import Button from '../../components/button/Button';

const buttonWidth = '79px';

function ClientInternalDashboard() {
  return (
    <div className="clientInternalDashboard">
      <Card>
        <div className="clientInternalDashboard-container">
          <div className="clientInternalDashboard-search">
            <SearchBar />
          </div>
          <div className="clientInternalDashboard-body">
            <table>
              <tr>
                <th>Client</th>
                <th>Mail</th>
                <th>Registry date</th>
                <th>Status</th>
                <th id="actions-title">Actions</th>
              </tr>
              <tr>
                <td id="listName">Amy Hogue</td>
                <td id="dateAdded">clients@speakeasy.com</td>
                <td id="dateAdded">Oct/31/2021</td>
                <td id="dateAdded">Pending</td>
                <td id="actions">
                  <Button use={'list'} size={'small'}>
                    Accept
                  </Button>
                  <Button type="secondary" width={buttonWidth} size={'small'}>
                    Decline
                  </Button>
                </td>
              </tr>

              <tr>
                <td id="listName">Joaquin Camara</td>
                <td id="dateAdded">clients@speakeasy.com</td>
                <td id="dateAdded">Oct/31/2021</td>
                <td id="dateAdded">Pending</td>
                <td id="actions">
                  <Button use={'list'} size={'small'}>
                    Accept
                  </Button>
                  <Button type="secondary" width={buttonWidth} size={'small'}>
                    Decline
                  </Button>
                </td>
              </tr>

              <tr>
                <td id="listName">Jessica Contreras</td>
                <td id="dateAdded">clients@speakeasy.com</td>
                <td id="dateAdded">Oct/31/2021</td>
                <td id="dateAdded">Accepted</td>
                <td id="actions">
                  <Button use={'list'} size={'small'}>
                    Edit
                  </Button>
                  <Button type="secondary" width={buttonWidth} size={'small'}>
                    Delete
                  </Button>
                </td>
              </tr>

              <tr>
                <td id="listName">Ian Rodriguez</td>
                <td id="dateAdded">clients@speakeasy.com</td>
                <td id="dateAdded">Oct/31/2021</td>
                <td id="dateAdded">Declined</td>
                <td id="actions">
                  <Button use={'list'} size={'small'}>
                    Edit
                  </Button>
                  <Button type="secondary" width={buttonWidth} size={'small'}>
                    Delete
                  </Button>
                </td>
              </tr>

              <tr>
                <td id="listName">Amy Hogue</td>
                <td id="dateAdded">clients@speakeasy.com</td>
                <td id="dateAdded">Oct/31/2021</td>
                <td id="dateAdded">Accepted</td>
                <td id="actions">
                  <Button use={'list'} size={'small'}>
                    Edit
                  </Button>
                  <Button type="secondary" width={buttonWidth} size={'small'}>
                    Delete
                  </Button>
                </td>
              </tr>

              <tr>
                <td id="listName">Joaquin Camara</td>
                <td id="dateAdded">clients@speakeasy.com</td>
                <td id="dateAdded">Oct/31/2021</td>
                <td id="dateAdded">Pending</td>
                <td id="actions">
                  <Button use={'list'} size={'small'}>
                    Accept
                  </Button>
                  <Button type="secondary" width={buttonWidth} size={'small'}>
                    Decline
                  </Button>
                </td>
              </tr>

              <tr>
                <td id="listName">Jessica Contreras</td>
                <td id="dateAdded">clients@speakeasy.com</td>
                <td id="dateAdded">Oct/31/2021</td>
                <td id="dateAdded">Pending</td>
                <td id="actions">
                  <Button use={'list'} size={'small'}>
                    Accept
                  </Button>
                  <Button type="secondary" width={buttonWidth} size={'small'}>
                    Decline
                  </Button>
                </td>
              </tr>

              <tr>
                <td id="listName">Ian Rodriguez</td>
                <td id="dateAdded">clients@speakeasy.com</td>
                <td id="dateAdded">Oct/31/2021</td>
                <td id="dateAdded">Declined</td>
                <td id="actions">
                  <Button use={'list'} size={'small'}>
                    Edit
                  </Button>
                  <Button type="secondary" width={buttonWidth} size={'small'}>
                    Delete
                  </Button>
                </td>
              </tr>

              <tr>
                <td id="listName">Ian Rodriguez</td>
                <td id="dateAdded">clients@speakeasy.com</td>
                <td id="dateAdded">Oct/31/2021</td>
                <td id="dateAdded">Declined</td>
                <td id="actions">
                  <Button use={'list'} size={'small'}>
                    Edit
                  </Button>
                  <Button type="secondary" width={buttonWidth} size={'small'}>
                    Delete
                  </Button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default ClientInternalDashboard;
