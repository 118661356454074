import React, { useState } from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import Card from '../../components/card/Card';
import Input from '../../components/input/Input';
import Button from '../../components/button/Button';
import { Rings } from 'react-loader-spinner';
import { useAuth } from '../../context/AuthContext';

import alert from './assets/alert-triangle.svg';
import Logo from '../assets/se_logo.svg';

function Login() {
  const { signIn } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const navigate = useNavigate();

  const handleLoginCallback = React.useCallback(
    (error) => {
      if (error) {
        setError('Email or password is incorrect. Please try again.');
      } else {
        navigate('/dashboard/overview');
      }
    },
    [setError, navigate]
  );

  const handleLogin = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsLoading(true);
    signIn(email, password, handleLoginCallback);
    setIsLoading(false);
  };

  return (
    <div className="login">
      <Card>
        <div className="login-form">
          <img
            style={{ width: 257, height: 80 }}
            src={Logo}
            alt="SpeakEasy Studios logo"
          />
          <p id="welcomeText">Welcome! Please log in to your account.</p>

          <form onSubmit={handleLogin}>
            {error && (
              <div className="login-form-error">
                <img
                  src={alert}
                  alt="red triangle with exclamation point in it"
                />
                {error}
              </div>
            )}
            <div className="login-form-input-container">
              <Input
                value={email}
                setter={setEmail}
                label={'Email'}
                type={'email'}
                fail={error}
              />
            </div>
            <div className="login-form-input-container">
              <Input
                value={password}
                setter={setPassword}
                label={'Password'}
                type={'password'}
                fail={error}
              />
            </div>
            <p
              onClick={() => navigate('/recoveryPassword')}
              id="forgotPasswordText"
            >
              Forgot your password?
            </p>
            <div className="login-form-access">
              <Button
                size={'big'}
                disabled={isLoading}
                icon={<Rings visible={isLoading} width="32" height="32" />}
              >
                {isLoading ? 'Logging In' : 'Log In'}
              </Button>
            </div>
          </form>

          <div className="login-form-bottom">
            <p id="bottomText">Not a member?</p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://share.hsforms.com/1kEdnRBugRx2sTx079kuabw1b93r"
              id="bottomTextHere"
            >
              Click here to join.
            </a>
          </div>
        </div>
      </Card>
    </div>
  );
}

export default Login;
