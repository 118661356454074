import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../context';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import {
  createCampaigns,
  createDrafts,
  editCampaign,
} from '../../../../services/campaign/campaignService';
import Card from '../../../../components/card/Card';
import Checkbox from '../../../../components/checkbox/Checkbox';
import Button from '../../../../components/button/Button';
import { CampaignContext } from '../../Campaign';

function AddOrDraft({ onHandleIsComplete }) {
  const { setIsCartFull } = useContext(AppContext);
  const { campaignDetails, setCampaignDetails, setCampaignAddedMessage } =
    useContext(CampaignContext);
  const [cartLoading, setCartLoading] = useState(undefined);
  const [draftLoading, setDraftLoading] = useState(undefined);
  const navigate = useNavigate();

  const handleAddToCart = async () => {
    onHandleIsComplete(true);
    try {
      if (campaignDetails.status === 'draft') {
        await editCampaign(
          { ...campaignDetails, status: 'pre-launch' },
          undefined,
          setCartLoading
        );
      } else {
        await createCampaigns(campaignDetails, undefined, setCartLoading);
      }
    } catch (e) {
      console.error(e);
      return;
    }
    setCampaignAddedMessage(true);
    setIsCartFull(true);
  };

  const handleSaveDraft = async () => {
    const isExistingDraft = !!campaignDetails.id;
    onHandleIsComplete(true);
    try {
      if (isExistingDraft) {
        await editCampaign({ ...campaignDetails }, undefined, setDraftLoading);
      } else {
        await createDrafts(campaignDetails, undefined, setDraftLoading);
      }
    } catch (e) {
      console.error(e);
      return;
    }
    navigate('/dashboard/campaignManager', {
      state: { clientName: campaignDetails.clientName },
    });
  };

  return (
    <Card>
      <div className="AddOrDraft">
        <div className="AddOrDraft-checkbox">
          <div style={{ boxSizing: 'border-box', marginTop: 4 }}>
            <Checkbox
              checked={campaignDetails.hasDisclaimer}
              onChange={() =>
                setCampaignDetails({
                  ...campaignDetails,
                  hasDisclaimer: !campaignDetails.hasDisclaimer,
                })
              }
            />
          </div>
          <p>
            My media complies with all local, state and federal political
            disclaimers laws.
          </p>
        </div>
        <div className="AddOrDraft-buttons">
          <div onClick={handleSaveDraft}>
            <Button
              type="secondary"
              disabled={!campaignDetails.hasDisclaimer}
              use={'save-draft'}
              size={'big'}
              loading={draftLoading}
            >
              {draftLoading ? 'Saving...' : 'Save draft'}
            </Button>
          </div>
          <div onClick={handleAddToCart}>
            <Button
              disabled={!campaignDetails.hasDisclaimer}
              size={'big'}
              loading={cartLoading}
            >
              {cartLoading ? 'Uploading...' : 'Add to cart'}
            </Button>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default AddOrDraft;
