import _ from 'lodash';
import {
  CampaignDetails,
  EditableCampaignFieldName,
  FetchedCampaign,
  FetchedCampaignAttributes,
} from 'models';

const VALUES_NOT_ALLOWED_IN_FORM_DATA = [undefined, ''];

function buildFetchedCampaignFromCampaignDetails(
  campaignDetails: CampaignDetails | undefined
) {
  const fetchedCampaign: FetchedCampaign = { attributes: {} };
  const { attributes } = fetchedCampaign;

  if (campaignDetails === undefined) {
    return undefined;
  }

  // TODO: Figure out a more elegant way of conditionally appending fields
  // to FormData when they're present in campaignDetails.
  if ('id' in campaignDetails) fetchedCampaign.id = campaignDetails.id;

  if ('status' in campaignDetails) attributes.status = campaignDetails.status;

  if ('campaignName' in campaignDetails)
    attributes.name = campaignDetails.campaignName;

  if ('clientId' in campaignDetails && 'clientName' in campaignDetails) {
    attributes.client = {
      id: campaignDetails.clientId,
      name: campaignDetails.clientName,
    };
  }

  if (
    'targetAudience' in campaignDetails &&
    campaignDetails.targetAudience !== undefined &&
    'id' in campaignDetails.targetAudience
  )
    attributes.audience = { id: campaignDetails.targetAudience.id };

  if ('url' in campaignDetails) attributes.landing_url = campaignDetails.url;

  if ('type' in campaignDetails) attributes.media_type = campaignDetails.type;

  if ('hasDisclaimer' in campaignDetails)
    attributes.has_disclaimer = campaignDetails.hasDisclaimer;

  if ('videoType' in campaignDetails)
    attributes.ad_video_format = campaignDetails.videoType;

  if ('banners' in campaignDetails) {
    attributes.media_files = campaignDetails.banners;
  }

  if ('budget' in campaignDetails) attributes.budget = campaignDetails.budget;

  if (
    'flightDates' in campaignDetails &&
    campaignDetails.flightDates !== undefined
  ) {
    if ('from' in campaignDetails.flightDates)
      attributes.start_date = campaignDetails.flightDates.from;
    if ('to' in campaignDetails.flightDates)
      attributes.end_date = campaignDetails.flightDates.to;
  }

  if ('draft' in campaignDetails) {
    attributes.draft = campaignDetails.draft;
  }

  if ('pendingChanges' in campaignDetails) {
    attributes.pending_changes = buildFetchedCampaignFromCampaignDetails(
      campaignDetails.pendingChanges
    );
  }

  return fetchedCampaign;
}

export function buildCampaignDetailsFromFetchedCampaign(
  fetchedCampaign: FetchedCampaign | undefined
): CampaignDetails | undefined {
  if (!fetchedCampaign) return undefined;
  const { attributes } = fetchedCampaign;

  const campaignDetails: CampaignDetails = {};

  if (_.isEmpty(attributes)) {
    return campaignDetails;
  }

  if ('id' in fetchedCampaign) campaignDetails.id = fetchedCampaign.id;
  if ('client' in attributes && attributes.client !== undefined) {
    campaignDetails.clientId = attributes.client.id;
    campaignDetails.clientName = attributes.client.name;
  }
  if ('media_type' in attributes) campaignDetails.type = attributes.media_type;
  if ('ad_video_format' in attributes)
    campaignDetails.videoType = attributes.ad_video_format;
  if ('name' in attributes) campaignDetails.campaignName = attributes.name;
  if ('media_files' in attributes)
    campaignDetails.banners = attributes.media_files;
  if ('landing_url' in attributes) campaignDetails.url = attributes.landing_url;
  if ('audience' in attributes)
    campaignDetails.targetAudience = attributes.audience;
  if ('start_date' in attributes || 'end_date' in attributes) {
    campaignDetails.flightDates = {
      ...(attributes.start_date ? { from: attributes.start_date } : undefined),
      ...(attributes.end_date ? { to: attributes.end_date } : undefined),
    };
  }
  if ('budget' in attributes) campaignDetails.budget = attributes.budget;
  if ('status' in attributes) campaignDetails.status = attributes.status;
  if ('has_disclaimer' in attributes)
    campaignDetails.hasDisclaimer = attributes.has_disclaimer;

  if ('pending_changes' in attributes) {
    const pendingChanges = buildCampaignDetailsFromFetchedCampaign(
      attributes.pending_changes
    );
    campaignDetails.pendingChanges = pendingChanges;
  }

  return campaignDetails;
}

function serializeFetchedCampaignToFormData(
  fetchedCampaign: FetchedCampaign | undefined
) {
  const formData = new FormData();

  if (!fetchedCampaign) return formData;

  if (fetchedCampaign.id)
    formData.append('campaign[id]', `${fetchedCampaign.id}`);

  const attributes = fetchedCampaign.attributes;

  Object.keys(attributes).forEach((attributeName) => {
    switch (attributeName) {
      case 'media_files': {
        const media_files = attributes[attributeName];
        if (media_files && media_files[0] instanceof File) {
          media_files.forEach((file) => {
            formData.append('campaign[media_files][]', file, file.name);
          });
        } else {
          formData.append('campaign[media_files][]', new Blob(media_files));
        }
        break;
      }
      case 'pending_changes': {
        let pendingChanges = attributes[attributeName];
        // If "pendingChanges" is undefined, we replace it with an empty
        // `{ attributes: {}}` object instead, because an undefined value
        // will be ignored by the backend. We don't want our value to be
        // ignored because this is how we delete "pendingChanges" (i.e. by
        // sending an empty value for it).
        if (pendingChanges === undefined) pendingChanges = { attributes: {} };

        validatePendingChangesOfFetchedCampaign(pendingChanges);

        formData.append(
          'campaign[pending_changes]',
          JSON.stringify(pendingChanges)
        );
        break;
      }
      case 'client': {
        if (attributes.client?.id) {
          formData.append('campaign[client_id]', attributes.client.id);
        }
        break;
      }
      case 'audience': {
        const audience = attributes[attributeName];
        if (audience?.id) {
          formData.append('campaign[audience_id]', `${audience.id}`);
        }
        break;
      }
      default: {
        if (
          VALUES_NOT_ALLOWED_IN_FORM_DATA.includes(attributes[attributeName])
        ) {
          return;
        }

        formData.append(
          `campaign[${attributeName}]`,
          attributes[attributeName]
        );
      }
    }
  });

  return formData;
}

export function serializeCampaignDetailsToFormData(
  campaignDetails: CampaignDetails
) {
  const fetchedCampaign =
    buildFetchedCampaignFromCampaignDetails(campaignDetails);
  const formData = serializeFetchedCampaignToFormData(fetchedCampaign);
  return formData;
}

export function isCampaignEditable(campaign: CampaignDetails | undefined) {
  if (!campaign || !campaign.status) return false;
  return ['draft', 'live'].includes(campaign.status);
}

type EditableCampaignDetailsFieldConfig = {
  name: EditableCampaignFieldName;
  correspondingFieldNamesInFetchedCampaignAttributes: (keyof FetchedCampaignAttributes)[];
  changesRequirePayment: boolean;
};

export const editableCampaignFields: EditableCampaignDetailsFieldConfig[] = [
  {
    name: 'flightDates',
    correspondingFieldNamesInFetchedCampaignAttributes: [
      'start_date',
      'end_date',
    ],
    changesRequirePayment: false,
  },
  {
    name: 'budget',
    correspondingFieldNamesInFetchedCampaignAttributes: ['budget'],
    changesRequirePayment: true,
  },
];

export const editableCampaignFieldNames = editableCampaignFields.map(
  (field) => field.name
);

const editableFetchedCampaignAttributeNames = editableCampaignFields
  .map(
    (fieldConfig) =>
      fieldConfig.correspondingFieldNamesInFetchedCampaignAttributes
  )
  .flat();

// Make sure there are no fields that are not allowed to be changed or
// that have "empty" values (e.g. {}, "", undefined, []).
export function validatePendingChangesOfFetchedCampaign(
  pendingChanges: FetchedCampaignAttributes['pending_changes']
): void {
  if (pendingChanges === undefined) return;

  const sanitizedPendingChangesAttributes = _.chain(pendingChanges.attributes)
    // leave only keys of attributes that allowed to be edited
    .pick(editableFetchedCampaignAttributeNames)
    // remove attribute keys whose values don't fit certain criteria
    .pickBy((fieldValue) => {
      if (fieldValue === undefined || fieldValue === null) {
        return false;
      }
      if (
        !_.isDate(fieldValue) &&
        _.isObject(fieldValue) &&
        Object.keys(fieldValue).length === 0
      ) {
        return false;
      }
      if (_.isArray(fieldValue) && fieldValue.length === 0) {
        return false;
      }
      return true;
    })
    .value();

  const sanitizedPendingChanges: FetchedCampaignAttributes['pending_changes'] =
    {
      attributes: sanitizedPendingChangesAttributes,
    };

  if (!_.isEqual(pendingChanges, sanitizedPendingChanges)) {
    throw new Error(
      'Error while validating pending_changes:\n' +
        JSON.stringify({
          pendingChanges,
          sanitizedPendingChanges,
        })
    );
  }
}
