import Card from '../card/Card';
import CardTitle from '../card/components/cardTitle/CardTitle';
import CardBody from '../card/components/cardBody/CardBody';
import styles from './DetailsAndSummaryLayout.module.scss';

const DetailsAndSummaryLayout = ({
  detailsTitle,
  detailsBody,
  summaryTitle,
  summaryBody,
  actions,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.details}>
        <Card>
          <CardTitle>{detailsTitle}</CardTitle>
          <CardBody className={styles.scroller}>{detailsBody}</CardBody>
        </Card>
      </div>
      <div className={styles.summary}>
        <Card>
          <CardTitle>{summaryTitle}</CardTitle>
          <CardBody>{summaryBody}</CardBody>
        </Card>
        {actions || null}
      </div>
    </div>
  );
};

export default DetailsAndSummaryLayout;
