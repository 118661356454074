import React from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import orderPlacedImg from './assets/orderPlaced.svg';
import Button from '../../../../components/button/Button';

function OrderPlaced() {
  const navigate = useNavigate();
  return (
    <div className="orderPlaced">
      <img src={orderPlacedImg} alt="Order placed" />
      <p className="orderPlaced-mainTitle">
        Your order placement is almost complete
      </p>
      <p className="orderPlaced-secondTitle">
        We sent you an email with payment details. Payment is required for your
        ad to go live. Be sure to check your spam folder if you do not receive
        the email within a couple minutes.
      </p>
      <p className="orderPlaced-secondTitle">
        Please contact studio@speakeasypolitical.com with any questions!
      </p>
      <div className="orderPlaced-actions-container">
        <div onClick={() => navigate('/dashboard/overview')}>
          <Button type="secondary" size={'small'}>
            Back home
          </Button>
        </div>
        <div onClick={() => navigate('/dashboard/orders')}>
          <Button type="secondary" size={'small'}>
            View orders
          </Button>
        </div>
      </div>
    </div>
  );
}

export default OrderPlaced;
