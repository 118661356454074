import React from 'react';
import './styles.scss';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import EmptyState from '../emptyState/EmptyState';
import Card from '../../../../components/card/Card';
import Button from '../../../../components/button/Button';

function Drafts({ draftsList }) {
  const navigate = useNavigate();
  return (
    <div className="draft">
      <Card size={'fullHeight'}>
        <div className="draft-header">
          <p>Drafts</p>
          <div onClick={() => navigate('/dashboard/campaignManager')}>
            <Button type="secondary" width="79px" size={'small'}>
              View all
            </Button>
          </div>
        </div>
        {_.isEmpty(draftsList) ? (
          <EmptyState
            size={'small'}
            useImage={false}
            text={'You have no drafts'}
            buttonText={'Create new campaign'}
          />
        ) : (
          <div className="draft-body">
            <table>
              <tbody>
                <tr>
                  <th>Ad Type</th>
                  <th>Client</th>
                  <th>Campaign</th>
                </tr>

                {draftsList.map((value, key) => {
                  return (
                    <tr key={`item-${key}`}>
                      <td id="listName">{value.attributes.client.name}</td>
                      <td id="dateAdded">{value.attributes.name}</td>
                      <td id="dateAdded">{value.attributes.media_type}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </Card>
    </div>
  );
}

export default Drafts;
