import { CampaignDetails } from 'models';
import { formatNumberToCurrency } from 'utils/currencyUtils';
import styles from './BudgetChangeSummary.module.scss';

type BudgetChangeSummaryProps = {
  currentBudget: number;
  additionalBudget: number;
  newBudgetTotal: number;
};

const BudgetChangeSummary = ({
  currentBudget,
  additionalBudget,
  newBudgetTotal,
}) => {
  return (
    <div>
      <div className={styles.section}>
        <div className={styles.line}>
          <span>Current Budget</span>
          <span>{formatNumberToCurrency(currentBudget)}</span>
        </div>
        <div className={styles.line}>
          <span>Additional Budget</span>
          <span>{formatNumberToCurrency(additionalBudget)}</span>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.line}>
          <span>Total Campaign Budget</span>
          <span>{formatNumberToCurrency(newBudgetTotal)}</span>
        </div>
        <div className={styles.line}>
          <span>Previously Paid Credit</span>
          <span>-{formatNumberToCurrency(currentBudget)}</span>
        </div>
      </div>
    </div>
  );
};

export const getBudgetChangeSummaryProps = (
  campaignBeforeChanges: CampaignDetails,
  campaignChanges: CampaignDetails
): BudgetChangeSummaryProps => {
  const currentBudget = campaignBeforeChanges.budget;

  if (!currentBudget)
    throw new Error('Unexpected state: campaign with undefined budget.');

  const newBudgetTotal = campaignChanges.budget || currentBudget;
  const additionalBudget = newBudgetTotal - currentBudget;

  return {
    currentBudget,
    additionalBudget,
    newBudgetTotal,
  };
};

export default BudgetChangeSummary;
