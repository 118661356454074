import React, { useEffect, useState } from 'react';
import './styles.scss';
import { Rings } from 'react-loader-spinner';
import { getAllCampaigns } from '../../services/campaign/campaignService';
import InfoCard from '../../components/infoCard/InfoCard';
import ActiveCampaignsList from './components/activeCampaigns/ActiveCampaigns';
import Drafts from './components/drafts/Drafts';

function Overview() {
  const [cliensAmount, SetclientAmount] = useState(0);
  const [activeCampaigns, setActiveCampaigns] = useState([]);
  const [draftCampaigns, setDraftCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Promise.all([fetchClientsAmount(), fetchActiveCampaigns()]).then(() =>
      setIsLoading(false)
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchClientsAmount = async () => {
    SetclientAmount(cliensAmount);
  };

  const fetchActiveCampaigns = async () => {
    let list = await getAllCampaigns();
    let filteredLiveCampaignsList = await list.filter(
      (element) => element.attributes.status === 'live'
    );
    let filteredDraftCampaignsList = await list.filter(
      (element) => element.attributes.status === 'draft'
    );
    setActiveCampaigns(filteredLiveCampaignsList);
    setDraftCampaigns(filteredDraftCampaignsList);
  };

  if (isLoading) {
    return (
      <div
        style={{
          margin: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          height: '100vh',
        }}
      >
        <Rings color="#D6EF59" />
      </div>
    );
  }

  return (
    <div className="overview">
      <div className="overview-graphContainer">
        <ActiveCampaignsList campaignList={activeCampaigns} />
      </div>
      <div className="overview-flexContainerRow">
        <InfoCard
          number={activeCampaigns.length}
          icon={'campaign'}
          text={'active campaigns'}
        />
        <Drafts draftsList={draftCampaigns} />
      </div>
    </div>
  );
}

export default Overview;
