import { useState, useEffect } from 'react';
import './App.scss';
import { AppContext } from './context';
import _ from 'lodash';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import { AuthContextProvider } from 'context/AuthContext';
import { auth } from './firebase';
import useFirebaseAuthentication from 'hooks/useFirebaseAuthentication';

import ProtectedRoute from 'feature/protectedRoute/ProtectedRoute';
import Dashboard from 'layouts/Dashboard';
import Overview from 'feature/overview/Overview';
import Campaign from 'feature/campaign/Campaign';
import CampaignManager from 'feature/campaignManager/CampaignManager';
import Audiences from 'feature/audiences/Audiences';
import Clients from 'feature/clients/Clients';
import ClientInternalDashboard from 'feature/clientInternalDashboard/ClientInternalDasboard';
import OrdersInternalDashboard from 'feature/OrdersInternalDashboard/OrdersInternalDashboard';
import CampaignManagerAdmin from 'feature/campaignManagerAdmin/CampaignManagerAdmin';
import Cart from 'feature/cart/Cart';
import Orders from 'feature/orders/Orders';
import Login from 'feature/login/Login';
import RecoverPassword from 'feature/recoverPassword/RecoverPassword';
import SetUpANewPassword from 'feature/setUpANewPassword/SetUpANewPassword';
import Faq from 'feature/faq/Faq';

export default function App() {
  const firebaseAuth = useFirebaseAuthentication(auth);
  const { state } = useLocation();

  const [isCartFull, setIsCartFull] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(JSON.parse(localStorage.getItem('campaignList') || '{}'))) {
      setIsCartFull(true);
    } else {
      setIsCartFull(false);
    }
  }, []);

  return (
    <AppContext.Provider
      value={{
        isCartFull,
        setIsCartFull,
        ...firebaseAuth,
      }}
    >
      <AuthContextProvider>
        <div className="App">
          <Routes>
            <Route
              path="/"
              element={
                firebaseAuth.authenticated ? (
                  <Navigate replace to={state?.path || '/dashboard/overview'} />
                ) : (
                  <Login />
                )
              }
            />
            <Route path="/recoveryPassword" element={<RecoverPassword />} />
            <Route path="/update_password" element={<SetUpANewPassword />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            >
              <Route path="overview" element={<Overview />} />
              <Route path="campaigns">
                <Route index element={<Campaign />} />
                <Route path=":campaignId" element={<Campaign />} />
              </Route>
              <Route path="campaignManager" element={<CampaignManager />} />
              <Route path="audiences" element={<Audiences />} />
              <Route path="clients" element={<Clients />} />
              <Route
                path="clientsAdmin"
                element={<ClientInternalDashboard />}
              />
              <Route path="ordersAdmin" element={<OrdersInternalDashboard />} />
              <Route path="campaignAdmin" element={<CampaignManagerAdmin />} />
              <Route path="cart" element={<Cart />} />
              <Route path="orders" element={<Orders />} />
              <Route path="faq" element={<Faq />} />
            </Route>
          </Routes>
        </div>
      </AuthContextProvider>
    </AppContext.Provider>
  );
}
