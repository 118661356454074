import React, { useState, useEffect, createContext } from 'react';
import './styles.scss';
import AddType from './components/addType/AddType';
import CampaignAddedMessage from './components/campaignAddedMessage/CampaignAddedMessage';
import { useParams } from 'react-router-dom';
import { buildCampaignDetailsFromFetchedCampaign } from '../../utils/campaignUtils';
import { getCampaignById } from '../../services/campaign/campaignService';
import { Rings } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { isCampaignEditable } from '../../utils/campaignUtils';
import EditDraftCampaign from './components/editDraftCampaign/EditDraftCampaign';
import EditLiveCampaign from './components/editLiveCampaign/EditLiveCampaign';
import { CampaignDetails } from 'models';

function Campaign() {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const [campaignDetails, setCampaignDetails] = useState<
    CampaignDetails | undefined
  >(undefined);
  const [campaignAddedMessage, setCampaignAddedMessage] = useState(false);

  useEffect(() => {
    if (!campaignId) {
      setCampaignDetails({});
    } else {
      getCampaignById(campaignId).then((fetchedCampaign) => {
        const fetchedCampaignDetails =
          buildCampaignDetailsFromFetchedCampaign(fetchedCampaign);
        if (isCampaignEditable(fetchedCampaignDetails)) {
          setCampaignDetails(fetchedCampaignDetails);
        } else {
          console.error(
            `Editing of ${fetchedCampaignDetails?.status} is not permitted.`
          );
          navigate('/dashboard/campaignManager');
        }
      });
    }
  }, [campaignId, navigate]);

  if (!campaignDetails) {
    return (
      <div
        style={{
          margin: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          height: '100vh',
        }}
      >
        <Rings color="#D6EF59" />
      </div>
    );
  }

  const CampaignEditForm =
    campaignDetails.status === 'live' ? EditLiveCampaign : EditDraftCampaign;

  return (
    <CampaignContext.Provider
      value={{ campaignDetails, setCampaignDetails, setCampaignAddedMessage }}
    >
      {campaignAddedMessage ? (
        <div>
          <CampaignAddedMessage />
        </div>
      ) : (
        <div className="campaign">
          {!isCampaignTypeProperlySet(campaignDetails) ? (
            <AddType />
          ) : (
            CampaignEditForm && <CampaignEditForm />
          )}
        </div>
      )}
    </CampaignContext.Provider>
  );
}

type CampaignContextType = {
  campaignDetails: CampaignDetails;
  setCampaignDetails: (campaignDetails: CampaignDetails) => void;
  setCampaignAddedMessage: (isCampaignAdded: boolean) => void;
};

export const CampaignContext = createContext<CampaignContextType>(
  {} as CampaignContextType
);

function isCampaignTypeProperlySet(campaignDetails) {
  if (!campaignDetails.type) {
    return false;
  }

  if (!['video', 'banner'].includes(campaignDetails.type)) {
    return false;
  }

  if (campaignDetails.type === 'video' && !campaignDetails.videoType) {
    return false;
  }

  return true;
}

export default Campaign;
