import React from 'react';
import _ from 'lodash';
import { Link, useLocation } from 'react-router-dom';

function Breadcrumbs() {
  const basePath = '/dashboard';
  const routes = [
    { path: `${basePath}/overview`, name: 'Overview' },
    {
      path: `${basePath}/campaigns`,
      name: 'Create Campaign',
      parent: 'Campaigns',
    },
    { path: `${basePath}/campaignManager`, name: 'Campaigns' },
    { path: `${basePath}/campaignAdmin`, name: 'Campaign Admin' },
    { path: `${basePath}/orders`, name: 'Orders' },
    { path: `${basePath}/clientsAdmin`, name: 'Clients Admin' },
    { path: `${basePath}/ordersAdmin`, name: 'Orders Admin' },
    { path: `${basePath}/cart`, name: 'Checkout' },
    { path: `${basePath}/faq`, name: 'Support' },
  ];
  const location = useLocation();
  const matchedRoute = routes.filter(({ path }) =>
    location.pathname.endsWith(path)
  )[0];
  const matchedParent = _.has(matchedRoute, 'parent')
    ? routes.filter(({ name }) => matchedRoute.parent === name)[0]
    : '';

  return (
    <div className="topNave-url-container">
      <p className="topNav-url-container-home-title">
        <Link to="/dashboard/overview">Home</Link> /&nbsp;
        {matchedParent && (
          <Link to={matchedParent.path}>{matchedParent.name}</Link>
        )}
      </p>
      {matchedRoute && (
        <p className="topNav-url-container-page-title">
          <Link to={matchedRoute.path}>{matchedRoute.name}</Link>
        </p>
      )}
    </div>
  );
}

export default Breadcrumbs;
