export function convertBudgetToNumber(amount) {
  let amountAsNum = Number(amount);

  if (amount) {
    const cleanedAmountString = amount.toString().replace(/[\s,$]|[a-z]/gi, '');
    amountAsNum = Number(cleanedAmountString);
  }

  if (!isFinite(amountAsNum)) {
    return 0;
  }

  return amountAsNum;
}

/* Formats budget while typing in budget input */
export function formatBudget(amount) {
  if (!amount) return amount;

  const cleanedAmountString = amount.toString().replace(/[\s,$]|[a-z]/gi, '');
  let amountAsNum = convertBudgetToNumber(amount);
  if (amountAsNum === 0) {
    return undefined;
  }

  if (
    cleanedAmountString.match(/\./) !== null &&
    cleanedAmountString.match(/\.\d+/) === null
  ) {
    return `$${amountAsNum.toLocaleString('en-US')}.`;
  }

  return `$${amountAsNum.toLocaleString('en-US')}`;
}

/* Formats budget values in views (static values) */
export function formatNumberToCurrency(budgetString) {
  const currencyNumber = convertBudgetToNumber(budgetString);
  const currencyOptions = { style: 'currency', currency: 'USD' };
  const currency = new Intl.NumberFormat('en-US', currencyOptions).format(
    currencyNumber
  );

  return currency;
}
