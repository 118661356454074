import React, { useState } from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';
import nounLink from './assets/noun-link.svg';
import FaqDropdown from './components/faqDropdown/FaqDropdown';
import arrowUp from './assets/arrow-up.svg';
import chatBot from './assets/chat-bot.svg';
import HubspotContactForm from './components/hubspotContactForm/HubspotContactForm';

function Faq() {
  const faqs = [
    {
      title: 'Getting started',
      id: 'getting-started',
      questions: [
        'Getting started question one?',
        'Getting started question two?',
        'Getting started question three?',
        'Getting started question four?',
      ],
    },
    {
      title: 'Digital inventory',
      id: 'digital-inventory',
      questions: [
        'Digital inventory question one?',
        'Digital inventory question two?',
        'Digital inventory question three?',
        'Digital inventory question four?',
      ],
    },
    {
      title: 'Media reporting',
      id: 'media-reporting',
      questions: [
        'Media reporting question one?',
        'Media reporting question two?',
        'Media reporting question three?',
        'Media reporting question four?',
      ],
    },
    {
      title: 'Changes to existing campaigns',
      id: 'changes-to-existing-campaigns',
      questions: [
        'Changes to existing campaigns question one?',
        'Changes to existing campaigns question two?',
        'Changes to existing campaigns question three?',
        'Changes to existing campaigns question four?',
      ],
    },
    {
      title: 'About SpeakEasy',
      id: 'about-speakeasy',
      questions: [
        'About SpeakEasy question one?',
        'About SpeakEasy question two?',
        'About SpeakEasy question three?',
        'About SpeakEasy question four?',
      ],
    },
    {
      title: 'Creative Services',
      id: 'creative-services',
      questions: [
        'Creative Services question one?',
        'Creative Services question two?',
        'Creative Services question three?',
        'Creative Services question four?',
      ],
    },
  ];

  const [showHubspotContactForm, setShowHubspotContactForm] = useState(false);

  const quickLinks = faqs.map((faq, index, faqs) => {
    return (
      <a className={styles['no-style']} href={`#${faq.id}`} key={faq.id}>
        {faq.title}
        {index < faqs.length - 1 ? ',' : ''}
      </a>
    );
  });

  const generateFaq = faqs.map((faq) => {
    return (
      <React.Fragment key={faq.id}>
        <div className={styles['faq-sub-header']} id={faq.id}>
          {faq.title}
          <a href={`#${faq.id}`} className={styles['faq-sub-header-icon']}>
            <img
              src={nounLink}
              alt={faq.title}
              onClick={() => {
                navigator.clipboard.writeText(
                  `
                  ${window.location.href.split('#')[0]}#${faq.id}
                `.trim()
                );
              }}
            />
          </a>
          <hr className={styles['faq-sub-header-separator']} />
        </div>
        {faq.questions.map((question, index) => {
          return (
            <FaqDropdown
              title={question}
              id={`faq-dropdown-${faq.id}-${index}`}
              key={`${faq.id}-${index}`}
            />
          );
        })}
      </React.Fragment>
    );
  });

  return (
    <>
      {showHubspotContactForm && (
        <HubspotContactForm
          setShowHubspotContactForm={setShowHubspotContactForm}
        />
      )}
      <div
        className={styles['main-container']}
        id="media-studio-support-portal"
      >
        <div className={clsx(styles.faqContainers, styles.support)}>
          <div className={styles['support-header']}>
            Media Studio Support Portal
          </div>
          <hr className={styles['header-separator']} />
          <p className={styles['support-description']}>
            Our team of media strategists is standing by to help should you have
            any questions as you build and launch your digital programs!
          </p>
          <p
            className={clsx(
              styles['support-description'],
              styles['support-description-bottom']
            )}
          >
            You can also browse our FAQs below to see if one of our support
            articles helps answer your question.
          </p>
          <div className={styles['buttons-container']}>
            <div
              className={clsx(styles['button'], styles['button-send-note'])}
              onClick={() => setShowHubspotContactForm(true)}
            >
              <div className={styles['button-text']}>Send us a note</div>
            </div>
            <div className={clsx(styles['button'], styles['button-book-call'])}>
              <a
                href="https://meetings.hubspot.com/hello587/the-media-studio?__hstc=261477330.b6883406e2a35a6487884f408d44ff5d.1653430400154.1670448599799.1670459488694.53&__hssc=261477330.2.1670459488694&__hsfp=836151255"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className={styles['button-text']}>Book a call</div>
              </a>
            </div>
          </div>
        </div>

        <div className={clsx(styles.faqContainers, styles.faq)}>
          <div className={styles['faq-header']}>Frequently Asked Questions</div>
          <hr className={styles['header-separator']} />
          <div className={styles['faq-quick-links']}>
            <p>
              QUICK LINKS:
              {quickLinks}
            </p>
          </div>
          {generateFaq}

          <div className={styles['faq-back-to-top-container']}>
            <a
              className={styles['faq-back-to-top-container-anchor']}
              href="#media-studio-support-portal"
            >
              <img src={arrowUp} alt="Arrow Up" />{' '}
              <span className={styles['faq-back-to-top-container-text']}>
                Back to top
              </span>
            </a>
          </div>
        </div>
      </div>
      <div className={styles['chatbot-container']}>
        <img src={chatBot} alt="Chat Bot" />
      </div>
    </>
  );
}

export default Faq;
