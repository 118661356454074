import moment from 'moment';
import _ from 'lodash';
import video from './assets/video.svg';
import styles from './FullState.module.scss';
import DetailLine from './components/detailLine/DetailLine';
import { formatNumberToCurrency } from '../../../../utils/currencyUtils';
import { Link } from 'react-router-dom';

function FullState({ ordersList }) {
  return (
    <>
      {ordersList.map((campaign, key) => {
        return (
          <div className={styles.item} key={`order-${key}`}>
            <div className={styles.imageContainer}>
              {campaign.type === 'video' ? (
                <img
                  style={{ marginTop: 20, marginBottom: 0 }}
                  src={video}
                  alt="Video campaign icon"
                />
              ) : (
                <img
                  src={
                    !_.isEmpty(campaign.banners)
                      ? campaign.banners[0].url
                      : undefined
                  }
                  alt="campaignImg"
                />
              )}
            </div>
            <div className={styles.detailsContainer}>
              <Link
                to={`/dashboard/campaigns/${campaign.id}`}
                className={`${styles.campaignName}`}
              >
                {campaign.campaignName}
              </Link>
              <BudgetLine campaign={campaign} />
              <DetailLine name="Client" value={campaign.clientName} />
              <DetailLine
                name="Run dates"
                value={`From: ${moment(
                  String(campaign.flightDates?.from)
                ).format('MMM Do YY')} - To: ${moment(
                  String(campaign.flightDates?.to)
                ).format('MMM Do YY')}`}
              />
              <DetailLine
                name="Audience"
                value={campaign.targetAudience?.name}
              />
            </div>
          </div>
        );
      })}
    </>
  );
}

const BudgetLine = ({ campaign }) => {
  const pendingUpdatedBudgetAmount = campaign.pendingChanges?.budget;
  const additionalBudget = pendingUpdatedBudgetAmount
    ? pendingUpdatedBudgetAmount - campaign.budget
    : undefined;
  const [title, amount] = additionalBudget
    ? ['Additional budget', additionalBudget]
    : ['Budget', campaign.budget];
  return (
    <DetailLine name={title} value={`${formatNumberToCurrency(amount)}`} />
  );
};

export default FullState;
