import instance from '../axiosConfig';
const urlAsUser = process.env.REACT_APP_ORDERS;
const urlAsAdmin = process.env.REACT_APP_ORDERS_AS_ADMIN;

export const createOrders = (orders = []) => {
  const orderRequestPromises = orders.map((order) => {
    const orderFormData = new FormData();
    orderFormData.append('order[client_id]', order.client_id);
    orderFormData.append('order[campaign_id]', order.campaign_id);
    return instance.post(urlAsUser, orderFormData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  });

  return Promise.allSettled(orderRequestPromises);
};

export const getAllOrders = (url = urlAsUser) => {
  return instance.get(url).then(function (response) {
    // handle success
    return response.data.data;
  });
};

export const getAllOrdersAsAdmin = (url = urlAsAdmin) => {
  return instance.get(url).then(function (response) {
    // handle success
    return response.data.data;
  });
};

export const editOrderById = (order, url = urlAsAdmin) => {
  let orderFormData = new FormData();
  orderFormData.append('id', order.id);
  orderFormData.append('order[status]', order.status);

  return instance
    .put(`${url}/${order.id}`, orderFormData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(function (response) {
      // handle success
      return response.data.data;
    });
};
