import React from 'react';
import { ReactComponent as Add } from './assets/add.svg';
import styles from './Button.module.scss';

function Button({
  type = 'primary', // or "secondary"
  size,
  use,
  children,
  useIcon,
  onClick,
  disabled,
  asFormInput,
  width,
  loading,
}) {
  if (asFormInput && !(typeof children === 'string')) {
    throw new Error(`Children of "submit" button can only be string.`);
  }

  const iconContainer =
    (size === 'big' && useIcon && (
      <Add className={styles.add} alt="Add icon" />
    )) ||
    null;

  const className = `${styles.button} ${styles[type]} ${styles[size]} ${
    styles[use]
  } ${loading ? styles.loading : ''}`;

  if (asFormInput) {
    return (
      <input
        disabled={disabled}
        className={className}
        type={type === 'secondary' ? 'reset' : 'submit'}
        value={children}
      />
    );
  } else {
    return (
      <button
        disabled={disabled}
        onClick={onClick}
        className={className}
        style={{ width, position: 'relative' }}
      >
        {!!loading && (
          <div
            style={{
              height: '100%',
              width: loading,
              backgroundColor: 'white',
              opacity: '50%',
              position: 'absolute',
              left: 0,
            }}
          />
        )}
        {iconContainer}
        {children}
      </button>
    );
  }
}
export default Button;
