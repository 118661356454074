import React, { useState, useContext } from 'react';
import './styles.scss';
import _ from 'lodash';
import MediaUpload from '../../../../../../components/mediaUpload/MediaUpload';
import Preview from './components/preview/Preview';
import { CampaignContext } from '../../../../Campaign';

function UploadBanners({
  onHandleSuccess,
  onHandleBannerIsOpen,
  onHandleNextSectionOpen,
}) {
  const { campaignDetails, setCampaignDetails } = useContext(CampaignContext);
  const [banners, setBanners] = useState(campaignDetails.banners || []);
  const [canRemovePreviews, setCanRemovePreviews] = useState(false);
  const [videoDuration, setVideoDuration] = useState(
    campaignDetails.videoDuration || undefined
  );
  const handlerBanner = () => {
    setCampaignDetails({
      ...campaignDetails,
      banners,
      videoDuration,
      // Uploading new media requires that a user accept the disclaimer again,
      // hence 'hasDisclaimer: false'
      hasDisclaimer: false,
    });

    onHandleSuccess(true);
    onHandleBannerIsOpen(false);
    onHandleNextSectionOpen(true);
  };

  const handleSave = () => {
    setCanRemovePreviews(true);
    handlerBanner();
  };

  return (
    <div
      className={
        campaignDetails.type === 'banner'
          ? 'uploadBanners'
          : 'uploadBanners video'
      }
    >
      {campaignDetails.type === 'banner' ? (
        <>
          <p className="uploadBanners-text">
            Now we&apos;re ready to upload your creative
          </p>
          <p className="uploadBanners-sizes">
            Please upload banners in any (or preferably all) of these sizes:
          </p>
          <p className="uploadBanners-sizes">728 x 250 px</p>
          <p className="uploadBanners-sizes">300 x 250 px</p>
          <p className="uploadBanners-sizes">320 x 50 px</p>
          <p className="uploadBanners-sizes">300 x 600 px</p>{' '}
        </>
      ) : (
        <>
          <p className="uploadBanners-text">
            We can only serve 15 or 30s video ads that meet the below
            specifications.
          </p>
          <ul className="uploadBanners-sizes">
            <li>Resolution: 1280 x 720 or 1920 x 1080</li>
            <li>Aspect ratio: 16:9</li>
            <li>Constant Bitrate: 15-30 Mbps</li>
            <li>
              File Formats: QuickTime movie (.mov) or MPEG-4 (.mp4) format only
            </li>
          </ul>
        </>
      )}
      <MediaUpload
        className="uploadBanners-mediaUpload"
        type={campaignDetails.type}
        onHandleSetBanners={setBanners}
        onHandleSetVideoDuration={setVideoDuration}
        canRemovePreviews={canRemovePreviews}
        onHandleSetCanRemovePreviews={setCanRemovePreviews}
      />
      <Preview />
      <button
        className={`button  button--${
          _.isEmpty(banners) ? 'disabled' : 'active'
        }`}
        onClick={handleSave}
      >
        Save
      </button>
    </div>
  );
}

export default UploadBanners;
