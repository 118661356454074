import React from 'react';
import './styles.scss';
import check from './assets/check-circle.svg';
import alert from './assets/alert-triangle.svg';

function TextArea({ label, type, success, fail, returner, setter }) {
  return (
    <div className="container">
      <label htmlFor="">{label}</label>

      <div
        className={`textarea-container textarea-container--${
          (success && 'success') || (fail && 'fail')
        }`}
      >
        <textarea
          cols="40"
          rows="5"
          value={returner}
          onChange={(e) => setter(e.target.value)}
          placeholder={label}
          className="form--textarea"
          type={type}
        />
        {success && <img src={check} alt="green check mark" />}
        {fail && (
          <img src={alert} alt="red triangle with exclamation point in it" />
        )}
      </div>
      <span style={{ display: fail === true ? 'block' : 'none' }}>
        {label} is a required field
      </span>
    </div>
  );
}

export default TextArea;
