import { useContext } from 'react';
import './styles.scss';
import { CampaignContext } from '../../../../../../Campaign';
import MediaThumbnail from 'components/mediaThumbnail/MediaThumbnail';

function Preview() {
  const { campaignDetails, setCampaignDetails } = useContext(CampaignContext);

  return (
    <div className="preview">
      {campaignDetails.banners?.map((banner, index) => {
        return (
          <MediaThumbnail
            key={index}
            type={campaignDetails.type || 'banner'}
            src={campaignDetails.type === 'banner' ? banner.url : undefined}
            onClickDiscard={() => {
              const currentBanners = campaignDetails.banners || [];
              setCampaignDetails({
                ...campaignDetails,
                banners: [
                  ...currentBanners.slice(0, index),
                  ...currentBanners.slice(index + 1),
                ],
              });
            }}
            showDiscardButton={true}
            caption={banner.name}
          />
        );
      })}
    </div>
  );
}

export default Preview;
