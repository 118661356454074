import React, { useContext } from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import Card from '../../../../components/card/Card';
import campaignAdded from './assets/campaignAdded.svg';
import Button from '../../../../components/button/Button';
import { CampaignContext } from '../../Campaign';

function CampaignAddedMessage() {
  const navigate = useNavigate();
  const { setCampaignAddedMessage } = useContext(CampaignContext);

  const navigateToCart = () => {
    setCampaignAddedMessage(false);
    navigate('/dashboard/cart');
  };

  const navigateToCampaigns = () => {
    setCampaignAddedMessage(false);
    navigate('/dashboard/campaigns');
  };

  return (
    <div className="campaignAddedMessage">
      <div className="campaignAddedMessage-control">
        <Card>
          <p className="campaignAddedMessage-title">Added to cart</p>
          <img className="checkImg" src={campaignAdded} alt="Check" />
          <div className="campaignAddedMessage-actions">
            <div onClick={() => navigateToCart()}>
              <Button type="secondary" size={'big'}>
                View shopping cart
              </Button>
            </div>
            <div onClick={() => navigateToCampaigns()}>
              <Button size={'big'} useIcon>
                Create another campaign
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default CampaignAddedMessage;
