import React, { useState, useContext } from 'react';
import './styles.scss';
import _ from 'lodash';
import Input from '../../../../../../components/input/Input';
import { CampaignContext } from '../../../../Campaign';

function ClientName({
  onHandleSuccess,
  onHandleClienNameIsOpen,
  onHandleNextSectionOpen,
}) {
  const { campaignDetails, setCampaignDetails } = useContext(CampaignContext);
  const [clientName, setClienName] = useState(campaignDetails.clientName);
  const [onSuccess, setOnSucces] = useState(false);
  const handlerClientName = () => {
    setCampaignDetails({ ...campaignDetails, clientName });
    setOnSucces(true);
    onHandleSuccess(true);
    onHandleClienNameIsOpen(false);
    onHandleNextSectionOpen(true);
  };

  const handleState = (value) => {
    onHandleSuccess(false);
    setOnSucces(false);
    setClienName(value);
  };

  return (
    <div className="clientName">
      <p>First.. which client is this ad for?</p>
      <Input success={onSuccess} setter={handleState} returner={clientName} />
      <button
        className={`button  button--${
          _.isEmpty(clientName) ? 'disabled' : 'active'
        }`}
        onClick={() => handlerClientName(campaignDetails)}
      >
        Save
      </button>
    </div>
  );
}

export default ClientName;
