import clsx from 'clsx';
import CampaignChangeOrderSummary from 'feature/campaign/components/editLiveCampaign/components/campaignChangeOrderSummary/CampaignChangeOrderSummary';
import { getBudgetChangeSummaryProps } from 'feature/campaign/components/editLiveCampaign/components/campaignChangeOrderSummary/components/budgetChangeSummary/BudgetChangeSummary';
import _ from 'lodash';
import { CampaignDetails } from 'models';
import { ReactNode, useState } from 'react';
import { formatNumberToCurrency } from 'utils/currencyUtils';
import styles from './Summary.module.scss';

type Props = {
  campaignsInCart: CampaignDetails[];
};

const Summary = ({ campaignsInCart }: Props) => {
  let total = 0;
  return (
    <ul className={styles.container}>
      {campaignsInCart.map((campaign, index) => {
        if (!campaign.pendingChanges || _.isEmpty(campaign.pendingChanges)) {
          return (
            <li key={index}>
              <div className={styles.item}>
                <div>{campaign.campaignName}</div>
                <div>{formatNumberToCurrency(campaign.budget)}</div>
              </div>
            </li>
          );
        }
        const additionalBudget = getBudgetChangeSummaryProps(
          campaign,
          campaign.pendingChanges
        ).additionalBudget;
        total += additionalBudget;
        return (
          <li key={index}>
            <ExpandableOrderSummary
              orderName={campaign.campaignName}
              orderTotal={`${formatNumberToCurrency(additionalBudget)}`}
            >
              <CampaignChangeOrderSummary
                key={index}
                campaignBeforeChanges={campaign}
                campaignChanges={campaign.pendingChanges}
                showFieldsWithNoChanges={false}
                emphasizeTotal={false}
              />
            </ExpandableOrderSummary>
          </li>
        );
      })}
      <div className={clsx(styles.item, styles.total)}>
        <p>Total Amount Due:</p>
        <p>{`${formatNumberToCurrency(total)}`}</p>
      </div>
    </ul>
  );
};

type ExpandableOrderSummaryProps = {
  orderName: ReactNode;
  orderTotal: ReactNode;
  children: ReactNode;
};

const ExpandableOrderSummary = ({
  orderName,
  orderTotal,
  children,
}: ExpandableOrderSummaryProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <details
      onClick={(e) => {
        e.preventDefault();
        setIsOpen((prevIsOpen) => !prevIsOpen);
      }}
      {...(isOpen ? { open: true } : undefined)}
    >
      <summary>
        <div className={styles.item}>
          <div>
            {orderName}
            <button>{isOpen ? 'Hide' : 'Show'} details</button>
          </div>
          <div>{isOpen ? null : orderTotal}</div>
        </div>
      </summary>
      <div className={styles.detailsContainer}>{children}</div>
    </details>
  );
};

export default Summary;
