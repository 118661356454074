import React, { useContext, useState } from 'react';
import './styles.scss';
import { CampaignContext } from '../../Campaign';
import { formatDateRange } from '../../../../utils/dateUtils';
import IconButton from '../../../../components/iconButton/IconButton';
import PencilIcon from '../../../../components/iconButton/assets/pencil.svg';
import DetailsAndSummaryLayout from '../../../../components/detailsAndSummaryLayout/DetailsAndSummaryLayout';
import Button from '../../../../components/button/Button';
import CampaignChangeOrderSummary from './components/campaignChangeOrderSummary/CampaignChangeOrderSummary';
import AddBudget from './components/addBudget/AddBudget';
import { savePendingChangesToExistingCampaign } from '../../../../services/campaign/campaignService';
import { useNavigate } from 'react-router-dom';
import { formatNumberToCurrency } from '../../../../utils/currencyUtils';
import { editableCampaignFields } from '../../../../utils/campaignUtils';
import AddFlightDates from './components/addFlightDates/AddFlightDates';

function EditLiveCampaign() {
  const navigate = useNavigate();
  const { campaignDetails: campaign } = useContext(CampaignContext);
  const [campaignChanges, setCampaignChanges] = useState(
    campaign.pendingChanges || {}
  );
  const [fieldsBeingEdited, setFieldsBeingEdited] = useState({});

  const onFinishEditingCampaign = async () => {
    await savePendingChangesToExistingCampaign(campaign, campaignChanges);
    navigate('/dashboard/cart');
  };

  const editedFields = Object.keys(campaignChanges);

  return (
    <DetailsAndSummaryLayout
      detailsTitle={`Edit Your Campaign`}
      detailsBody={
        <>
          <Section title="Client">{campaign.clientName}</Section>
          <Section title="Campaign">{campaign.campaignName}</Section>
          {campaign.type === 'banner' ? (
            <Section
              title={`Banners (${campaign.banners?.length || 0})`}
              classSuffix="banners"
            >
              {(campaign.banners || []).map((banner, index) => (
                <img
                  key={index}
                  src={banner.url}
                  alt={`Campaign banner #${index + 1} ${
                    banner.name ? `(${banner.name})` : ''
                  }`}
                />
              ))}
            </Section>
          ) : (
            <Section title={`Videos (${campaign.videoDuration})`}>
              {console.error('Video preview not implemented') &&
                JSON.stringify(campaign.banners)}
            </Section>
          )}

          <Section title="Landing Page">{campaign.url}</Section>
          <Section title="Target Audience">
            {campaign.targetAudience?.name}
          </Section>

          <Section
            title="Flight Dates"
            editable
            onClickEdit={() =>
              setFieldsBeingEdited({ ...fieldsBeingEdited, flightDates: true })
            }
          >
            {fieldsBeingEdited['flightDates'] ? (
              <AddFlightDates
                currentFlightDates={campaign.flightDates}
                newFlightDates={campaignChanges.flightDates}
                onSaveFlightDates={(flightDates) => {
                  setCampaignChanges({
                    ...campaignChanges,
                    flightDates: flightDates,
                  });
                  setFieldsBeingEdited({
                    ...fieldsBeingEdited,
                    flightDates: false,
                  });
                }}
                onCancel={() =>
                  setFieldsBeingEdited({
                    ...fieldsBeingEdited,
                    flightDates: false,
                  })
                }
              />
            ) : (
              formatDateRange(
                campaignChanges.flightDates?.from || campaign.flightDates?.from,
                campaignChanges.flightDates?.to || campaign.flightDates?.to
              )
            )}
          </Section>
          <Section
            title="Budget"
            editable
            onClickEdit={() =>
              setFieldsBeingEdited({ ...fieldsBeingEdited, budget: true })
            }
          >
            {fieldsBeingEdited['budget'] ? (
              <AddBudget
                currentBudget={campaign.budget}
                additionalBudget={campaignChanges.budget - campaign.budget}
                onSaveAdditionalBudget={(additionalBudget) => {
                  if (!Number(additionalBudget)) {
                    delete campaignChanges['budget'];
                    setCampaignChanges({
                      ...campaignChanges,
                    });
                  } else {
                    setCampaignChanges({
                      ...campaignChanges,
                      budget:
                        Number(campaign.budget) + Number(additionalBudget),
                    });
                  }
                  setFieldsBeingEdited({
                    ...fieldsBeingEdited,
                    budget: false,
                  });
                }}
                onCancel={() =>
                  setFieldsBeingEdited({
                    ...fieldsBeingEdited,
                    budget: false,
                  })
                }
              />
            ) : (
              formatNumberToCurrency(campaignChanges.budget || campaign.budget)
            )}
          </Section>
        </>
      }
      summaryTitle="Change order summary"
      summaryBody={
        <CampaignChangeOrderSummary
          campaignBeforeChanges={campaign}
          campaignChanges={campaignChanges}
          emphasizeTotal={true}
        />
      }
      actions={
        <Button
          size="big"
          disabled={editedFields.length === 0}
          width="100%"
          onClick={onFinishEditingCampaign}
        >
          {editedFields.length &&
          editedFields.some((editedField) => {
            return fieldsThatRequirePaymentIfChanged.includes(editedField);
          })
            ? 'Save changes & Continue to checkout'
            : 'Save changes'}
        </Button>
      }
    />
  );
}

const fieldsThatRequirePaymentIfChanged = editableCampaignFields.reduce(
  (fieldList, currField) => {
    if (currField.changesRequirePayment) {
      fieldList.push(currField.name);
    }
    return fieldList;
  },
  []
);

const Section = ({
  title,
  classSuffix,
  children,
  editable = false,
  onClickEdit,
}) => {
  return (
    <div
      className={`editLiveCampaign-section 
      ${classSuffix ? 'editLiveCampaign-section-' + classSuffix : ''}`}
    >
      <div className="editLiveCampaign-section-title">
        {title}{' '}
        {editable ? (
          <IconButton icon={PencilIcon} onClick={onClickEdit} />
        ) : null}
      </div>
      <div className="editLiveCampaign-section-body">{children}</div>
    </div>
  );
};

export default EditLiveCampaign;
