import React, { useEffect, useState } from 'react';
import './styles.scss';
import _ from 'lodash';
import { Rings } from 'react-loader-spinner';
import moment from 'moment';
import { getAllOrders } from '../../services/orders/ordersService';
import { getCampaignByIdAsAdmin } from '../../services/campaign/campaignService';
import Card from '../../components/card/Card';
import SearchBar from '../../components/searchBar/SearchBar';
import Button from '../../components/button/Button';
import EmptyState from '../../feature/overview/components/emptyState/EmptyState';

function Orders() {
  const [isLoading, setIsLoading] = useState(false);
  const [ordersList, setOrdersList] = useState([]);
  const [filterByClientName, setFilterByClientName] = useState('');
  const regExp = RegExp(
    `.*${filterByClientName.toLowerCase().split('').join('.*')}.*`
  );

  useEffect(() => {
    fetchAllOrders();
  }, []);

  const fetchAllOrders = async () => {
    setIsLoading(true);
    let fetchedOrders = await getAllOrders();
    if (!_.isEmpty(fetchedOrders)) {
      setOrdersList(fetchedOrders);
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return (
      <div
        style={{
          margin: 'auto',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          height: '100vh',
        }}
      >
        <Rings color="#D6EF59" />
      </div>
    );
  }

  const emptyState = (text) => {
    return (
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          margin: 'auto',
          height: '50vh',
        }}
      >
        <EmptyState
          useImage="true"
          text={text}
          buttonText="Create new campaign"
          size="small"
        />
      </div>
    );
  };

  const renderList = (filteredResults) => {
    return (
      <div className="campaignsInternalDashboard-body">
        <table>
          <thead>
            <tr>
              <th>Client</th>
              <th>Campaign</th>
              <th>Budget</th>
              <th>Start - End</th>
              <th>Time remaining</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredResults.map((order, key) => {
              let orderKey = `${key}-order`;
              let campaignID = order.attributes.campaign.id;
              return (
                <tr key={orderKey}>
                  <td id="listName">{order.attributes.client.name}</td>
                  <td id="dateAdded">{order.attributes.campaign.name}</td>
                  <td id="dateAdded">${order.attributes.campaign.budget}</td>
                  <td id="dateAdded">
                    {moment(
                      String(order.attributes.campaign.start_date)
                    ).format('MM/DD/YY')}{' '}
                    -{' '}
                    {moment(String(order.attributes.campaign.end_date)).format(
                      'MM/DD/YY'
                    )}
                  </td>
                  <td id="dateAdded">
                    {Math.abs(
                      moment
                        .duration(
                          moment(order.attributes.campaign.start_date).diff(
                            moment(order.attributes.campaign.end_date)
                          )
                        )
                        .asDays()
                    )}{' '}
                    days
                  </td>
                  <td id="dateAdded">{order.attributes.status}</td>
                  <td
                    onClick={() => getCampaignByIdAsAdmin(campaignID)}
                    id="dateAdded"
                  >
                    <Button size={'small'}>Download media</Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const renderOrderList = () => {
    let filteredResults = ordersList
      .sort()
      .filter((value) =>
        value.attributes.client.name.toLowerCase().match(regExp)
      );

    return filteredResults.length === 0
      ? emptyState('No results matched!')
      : renderList(filteredResults);
  };

  return (
    <div className="campaignsInternalDashboard">
      <Card>
        <div className="campaignsInternalDashboard-container">
          <div className="campaignsInternalDashboard-search">
            <SearchBar onHandleSetFilterByClientName={setFilterByClientName} />
          </div>
          {Object.keys(ordersList).length === 0
            ? emptyState('No orders quite yet!')
            : renderOrderList()}
        </div>
      </Card>
    </div>
  );
}

export default Orders;
