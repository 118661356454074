import { useEffect } from 'react';
import styles from './MediaUpload.module.scss';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone, {
  IDropzoneProps,
  IFileWithMeta,
  IMeta,
} from 'react-dropzone-uploader';
import { getDroppedOrSelectedFiles } from 'html5-file-selector';
import BannerSelector from './components/bannerSelector/BannerSelector';
import clsx from 'clsx';
import MediaThumbnail from 'components/mediaThumbnail/MediaThumbnail';
import VideoSelector from './components/videoSelector/VideoSelector';

const MAX_HEIGHT_FOR_LANDSCAPE_BANNER = 600; // px
const MAX_WIDTH_FOR_PORTRAIT_BANNER = 768; // px

let banners: File[] = [];
let filesInPreview: IFileWithMeta[] = [];

type Props = {
  type: 'video' | 'banner';
  onHandleSetBanners: (banners: File[]) => void;
  onHandleSetVideoDuration: (duration: IMeta['duration']) => void;
  canRemovePreviews: boolean;
  onHandleSetCanRemovePreviews: (canRemovePreviews: boolean) => void;
  className?: string;
};

function MediaUpload({
  type,
  onHandleSetBanners,
  onHandleSetVideoDuration,
  canRemovePreviews,
  onHandleSetCanRemovePreviews,
  className,
}: Props) {
  useEffect(() => {
    if (canRemovePreviews) {
      while (filesInPreview && filesInPreview.length > 0) {
        filesInPreview[0].remove();
      }
      onHandleSetCanRemovePreviews(false);
    }
  }, [canRemovePreviews, onHandleSetCanRemovePreviews]);

  const onFileChange: IDropzoneProps['onChangeStatus'] = (
    fileWithMetadata,
    status,
    allFiles
  ) => {
    const { file, meta } = fileWithMetadata;
    if (status === 'error_validation') {
      fileWithMetadata.remove();
      setTimeout(() => alert(meta.validationError), 100);
    }

    if (status === 'done') {
      if (type === 'video') {
        onHandleSetVideoDuration(meta.duration);
        banners.push(file);
        onHandleSetBanners(banners);
      } else {
        banners.push(file);
        onHandleSetBanners(banners);
      }

      filesInPreview = allFiles;
    }
  };

  const onSubmit = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
  };

  const getFilesFromEvent: IDropzoneProps['getFilesFromEvent'] = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
        banners = [];
      });
    });
  };
  const selectFileInput: IDropzoneProps['InputComponent'] = ({
    accept,
    onFiles,
    getFilesFromEvent,
  }) => {
    return (
      <label>
        {type === 'video' ? <VideoSelector /> : <BannerSelector />}

        <input
          style={{ display: 'none' }}
          type="file"
          accept={accept}
          multiple
          onChange={(e) => {
            getFilesFromEvent(e).then((chosenFiles) => {
              onFiles(chosenFiles);
            });
          }}
        />
      </label>
    );
  };

  return (
    <Dropzone
      styles={{
        dropzone: {
          alignItems: type === 'video' ? 'flex-start' : 'center',
        },
      }}
      validate={type === 'banner' ? validateBannerDimensions : undefined}
      autoUpload={false}
      onSubmit={onSubmit}
      onChangeStatus={onFileChange}
      InputComponent={selectFileInput}
      getFilesFromEvent={getFilesFromEvent}
      accept={type === 'banner' ? 'image/*' : 'video/*'}
      maxFiles={5}
      inputContent="Drop A File"
      PreviewComponent={({ fileWithMeta }) => {
        return (
          <MediaThumbnail
            src={type === 'banner' ? fileWithMeta.meta.previewUrl : undefined}
            type={type}
            caption={fileWithMeta.meta.name}
            showDiscardButton={true}
            onClickDiscard={fileWithMeta.remove}
          />
        );
      }}
      LayoutComponent={({
        input,
        previews,
        dropzoneProps,
        files,
        extra: { maxFiles },
      }) => {
        return (
          <div className={clsx(styles.container, className)}>
            <div className={styles.thumbnailsContainer}>{previews}</div>
            {files.length < maxFiles && <div {...dropzoneProps}>{input}</div>}
          </div>
        );
      }}
    />
  );
}

const validateBannerDimensions: NonNullable<IDropzoneProps['validate']> = ({
  meta,
}) => {
  const { width, height } = meta;
  if (width === undefined || height === undefined) {
    return (
      'Missing width or height from image metadata: ' + JSON.stringify(meta)
    );
  }

  const isPortraitAspectRatio = height > width;
  const isLandscapeAspectRatio = width > height;

  if (isPortraitAspectRatio && width > MAX_WIDTH_FOR_PORTRAIT_BANNER) {
    return `Portrait banners can't be wider than ${MAX_WIDTH_FOR_PORTRAIT_BANNER}px.`;
  }
  if (isLandscapeAspectRatio && height > MAX_HEIGHT_FOR_LANDSCAPE_BANNER) {
    return `Landscape banners can't be taller than ${MAX_HEIGHT_FOR_LANDSCAPE_BANNER}px.`;
  }

  const MAX_WIDTH_FOR_SQUARE_BANNER = Math.max(
    MAX_HEIGHT_FOR_LANDSCAPE_BANNER,
    MAX_WIDTH_FOR_PORTRAIT_BANNER
  );
  if (width > MAX_WIDTH_FOR_SQUARE_BANNER) {
    return `Square banners can't be wider than ${MAX_WIDTH_FOR_SQUARE_BANNER}px.`;
  }

  return ''; // "falsy" return value implies that image passes validation.
};

export default MediaUpload;
