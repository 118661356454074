import React, { useState } from 'react';
import './styles.scss';
import { useLocation } from 'react-router-dom';
import { updateForgottenPassword } from '../../services/user/userServices';
import Card from '../../components/card/Card';
import Input from '../../components/input/Input';
import Button from '../../components/button/Button';
import SuccessMessage from './components/successMessage/SuccessMesage';

import Logo from '../assets/se_logo.svg';

function SetUpANewPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [notEqualPasswords, setNotEqualPasswords] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  const handleUpdateForgotenPassword = (userInfo) => {
    if (newPassword === confirmPassword) {
      updateForgottenPassword(userInfo);
      setUpdateSuccess(true);
    } else {
      setNotEqualPasswords(true);
    }
  };

  return (
    <div className="setUpANewPassword">
      <Card>
        <div className="setUpANewPassword-form">
          <img
            style={{ width: 257, height: 80, marginBottom: 64 }}
            src={Logo}
            alt="SpeakEasy Studios logo"
          />
          {!updateSuccess ? (
            <div>
              <p id="recoveryText">Almost there!</p>
              <p id="recoveryText">let&apos;s set up a new password</p>

              <div className="setUpANewPassword-form-input-container">
                <Input
                  value={newPassword}
                  setter={setNewPassword}
                  label={'NEW PASSWORD'}
                  type={'password'}
                  fail={notEqualPasswords}
                />
              </div>

              <div className="setUpANewPassword-form-input-container">
                <Input
                  value={confirmPassword}
                  setter={setConfirmPassword}
                  label={'CONFIRM NEW PASSWORD'}
                  type={'password'}
                  fail={notEqualPasswords}
                />
              </div>

              <div
                onClick={() => {
                  handleUpdateForgotenPassword({
                    token: token,
                    newPassword: newPassword,
                  });
                }}
                className="setUpANewPassword-form-access"
              >
                <Button
                  disabled={
                    newPassword.length === 0 && confirmPassword.length === 0
                      ? true
                      : false
                  }
                  size={'big'}
                >
                  Create new password
                </Button>
              </div>
            </div>
          ) : (
            <SuccessMessage />
          )}
        </div>
      </Card>
    </div>
  );
}

export default SetUpANewPassword;
