import React from 'react';
import './styles.scss';
import Button from '../../../../components/button/Button';
import { useNavigate } from 'react-router-dom';

function SuccessMessage() {
  const navigate = useNavigate();

  return (
    <div className="successMessage">
      <p id="succesText">Hurray!</p>
      <p id="succesText">Your password reset was successful</p>
      <p id="loginMessage">
        You can now Log In to the studio and access your account
      </p>

      <div onClick={() => navigate('/')}>
        <Button use="navigateToLogin" width={'212px'} size={'big'}>
          Log In
        </Button>
      </div>
    </div>
  );
}

export default SuccessMessage;
