import React from 'react';
import './styles.scss';
import search from './assets/search.svg';

function SearchBar({ onHandleSetFilterByClientName }) {
  return (
    <div className="search-container">
      <input
        placeholder="Search"
        type="text"
        onChange={(e) => onHandleSetFilterByClientName(e.target.value)}
      />
      <img alt="search icon" src={search} />
    </div>
  );
}

export default SearchBar;
