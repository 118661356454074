import instance from '../axiosConfig';
const URL = process.env.REACT_APP_AUDIENCES;
export const createAudiences = (audienceDetails, url = URL) => {
  return instance
    .post(url, {
      audience: {
        name: audienceDetails.name,
        audience_type: 'built',
        district: audienceDetails.state,
        geographical_area: audienceDetails.geographicArea,
        party_affiliation: 'democrats',
        voters: ['test', 'test'],
        van_list: 'test',
        detail: 'test',
      },
    })
    .then((response) => {
      const audience = response.data && response.data.data; // data is sometimes nested
      if (!(audience && audience.id)) {
        throw new Error('Missing audience ID in createAudiences response.');
      }
      return audience;
    });
};

export const getAllAudiences = (url = URL) => {
  return instance.get(url).then((response) => {
    return response.data.data;
  });
};
