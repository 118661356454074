import React from 'react';
import './styles.scss';
import _ from 'lodash';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/button/Button';
import Card from '../../../../components/card/Card';
import EmptyState from '../emptyState/EmptyState';

function ActiveCampaignsList({ campaignList }) {
  const navigate = useNavigate();

  return (
    <div className="activeCampaignsList">
      <Card size={'fullHeight'}>
        <div
          className={`activeCampaignsList-header activeCampaignsList-header${
            _.isEmpty(campaignList) && '-empty-State'
          }`}
        >
          <p>Active Campaigns</p>
          <div onClick={() => navigate('/dashboard/campaignManager')}>
            <Button type="secondary" width="79px" size={'small'}>
              View all
            </Button>
          </div>
        </div>
        {_.isEmpty(campaignList) ? (
          <EmptyState
            size={'big'}
            useImage
            buttonText={'Create new campaigns'}
            text={'There are no active campaigns '}
          />
        ) : (
          <div className="activeCampaignsList-body">
            <table>
              <tbody>
                <tr>
                  <th>Candidate</th>
                  <th>Campaign</th>
                  <th>Flight dates</th>
                  <th>Ad Type</th>
                </tr>

                {campaignList.map((value, key) => {
                  return (
                    <tr key={`item-${key}`}>
                      <td id="listName">{value.attributes.client.name}</td>
                      <td id="dateAdded">{value.attributes.name}</td>
                      <td id="dateAdded">
                        {moment(String(value.attributes.start_date)).format(
                          'MM/DD/YY'
                        )}{' '}
                        -{' '}
                        {moment(String(value.attributes.end_date)).format(
                          'MM/DD/YY'
                        )}
                      </td>
                      <td id="dateAdded">{value.attributes.media_type}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </Card>
    </div>
  );
}

export default ActiveCampaignsList;
