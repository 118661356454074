import { useState } from 'react';
import Button from '../../../../../../components/button/Button';
import { formatNumberToCurrency } from '../../../../../../utils/currencyUtils';
import styles from './AddBudget.module.scss';

const AddBudget = ({
  onSaveAdditionalBudget,
  onCancel,
  currentBudget,
  additionalBudget,
}) => {
  const [value, setValue] = useState(additionalBudget || '');
  return (
    <form
      className={styles.container}
      onReset={onCancel}
      onSubmit={(e) => {
        e.preventDefault();
        onSaveAdditionalBudget(value);
      }}
    >
      <div className={styles.currentBudget}>
        <label htmlFor={'currentBudget'}>Current Budget</label>
        <div>
          $
          <input
            readOnly
            disabled
            type="text"
            value={formatNumberToCurrency(currentBudget)}
          />
        </div>
      </div>
      <div className={styles.additionalBudget}>
        <label htmlFor={'additionalBudget'}>Additional Amount</label>
        <div className={styles.inputWrapper}>
          $
          <input
            autoFocus
            type="number"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.buttons}>
        <Button type="secondary" asFormInput size="big">
          Cancel
        </Button>
        <Button asFormInput size="big">
          Save
        </Button>
      </div>
    </form>
  );
};

export default AddBudget;
