import React from 'react';
import './styles.scss';

function TopNav({ onHandleFilterByStatus, status }) {
  return (
    <div className="topNavManager">
      <p
        className={status === 'all' || status === '' ? 'active' : ''}
        onClick={() => onHandleFilterByStatus('all')}
      >
        All
      </p>
      <p
        className={status.includes('live') ? 'active' : ''}
        onClick={() => onHandleFilterByStatus(['live', 'pause-requested'])}
      >
        Active
      </p>
      <p
        className={status === 'pre-launch' ? 'active' : ''}
        onClick={() => onHandleFilterByStatus('pre-launch')}
      >
        Upcoming
      </p>
      <p
        className={status.includes('paused') ? 'active' : ''}
        onClick={() => onHandleFilterByStatus(['paused', 'start-requested'])}
      >
        Paused
      </p>
      <p
        className={status === 'canceled' ? 'active' : ''}
        onClick={() => onHandleFilterByStatus('canceled')}
      >
        Canceled
      </p>
      <p
        className={status === 'ended' ? 'active' : ''}
        onClick={() => onHandleFilterByStatus('ended')}
      >
        Ended
      </p>
      <p
        className={status === 'draft' ? 'active' : ''}
        onClick={() => onHandleFilterByStatus('draft')}
      >
        Drafts
      </p>
    </div>
  );
}

export default TopNav;
