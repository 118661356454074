import instance from '../axiosConfig';
const URL = process.env.REACT_APP_BASE_URL;
const urlPass = process.env.REACT_APP_USER_PASS;
const urlUserAsAdmin = process.env.REACT_APP_USERS_AS_ADMIN;

export const handleFirebaseLogin = () => {
  return instance.get('/my_account', { baseURL: URL }).then((resp) => {
    return resp.data.data;
  });
};

export const createUsersAsAdmin = (user, url = urlUserAsAdmin) => {
  let userFormData = new FormData();

  userFormData.append('user[email]', user.email);
  userFormData.append('user[password]', user.password);
  userFormData.append('user[first_name]', user.firstName);
  userFormData.append('user[last_name]', user.lastName);
  userFormData.append('user[admin]', user.isAdmin);

  return instance
    .post(url, userFormData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(function (response) {
      // handle success
      return response.data.data;
    });
};

export const updateForgottenPassword = (userInfo, url = urlPass) => {
  return instance.put(url, {
    user: {
      reset_password_token: userInfo.token,
      password: userInfo.newPassword,
    },
  });
};
