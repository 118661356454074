import React from 'react';
import './styles.scss';

function VideoSelector() {
  return (
    <div className="video-selector-container">
      <p className="upload-video-label">UPLOAD VIDEO</p>
      <div className="browse-files-button">Browse Files</div>
    </div>
  );
}

export default VideoSelector;
