import React, { useState, useEffect, useContext } from 'react';
import './styles.scss';
import { AppContext } from '../../context';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import Cart from './assets/shopping-cart.svg';
import FullCart from './assets/shopping-cart-full.svg';

import Button from '../button/Button';
import UserDropdown from './components/userDropdown/UserDropdown';
import Breadcrumbs from '../../feature/breadcrumbs/Breadcrumbs';

function TopNav() {
  const [campaignList, setCampaignList] = useState(false);
  const navigate = useNavigate();
  const { isCartFull } = useContext(AppContext);

  useEffect(() => {
    let list = JSON.parse(localStorage.getItem('campaignList'));
    if (!_.isEmpty(list)) {
      setCampaignList(!campaignList);
    } else {
      setCampaignList(false);
    }
  }, [isCartFull]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="topNav">
      <Breadcrumbs />
      <div className="topNav-user-info">
        <div
          onClick={() => {
            navigate('/dashboard/campaigns');
          }}
        >
          <Button useIcon={true} size={'big'}>
            Create campaign
          </Button>
        </div>
        <UserDropdown />
        <img
          onClick={() => navigate('/dashboard/cart')}
          src={isCartFull ? FullCart : Cart}
          alt="shop cart icon"
        />
      </div>
    </div>
  );
}

export default TopNav;
