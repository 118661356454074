import React from 'react';
import { useNavigate } from 'react-router-dom';
import './styles.scss';
import emptyState from '../../assets/vector_emptyCart.svg';
import Button from '../../../../components/button/Button';

function EmptyState() {
  const navigate = useNavigate();
  return (
    <div className="emptyState">
      <img src={emptyState} alt="Empty State" />
      <p className="emptyState-mainTitle">Your cart is empty</p>
      <p className="emptyState-secondTitle">
        Create a campaign to get started.
      </p>
      <div onClick={() => navigate('/dashboard/campaigns')}>
        <Button size={'big'} useIcon={true}>
          Create new campaign
        </Button>
      </div>
    </div>
  );
}

export default EmptyState;
