import React from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';

function MailSentSuccess() {
  const navigate = useNavigate();

  return (
    <div className="mailSentSuccess">
      <p id="mailSentSuccessText">We&apos;ve received your request!</p>

      <div className="mailSentSuccess-messagesContainer">
        <p className="mailSentSuccess-mailSentSuccessMessage">
          You can now Log In to the studio and access your account.
        </p>
        <p className="mailSentSuccess-mailSentSuccessMessage">
          You will soon receive an email with the next steps for you to reset
          your password.
        </p>
      </div>

      <div>
        <p className="mailSentSuccess-mailSentSuccessMessage">
          It can take a few minutes. If you don&apos;t receive an email please
          shoot a message to{' '}
          <a
            className="mailSentSuccess-mailSentSuccessMessageMail"
            href="mailto:support@speakeasypolitical.com"
          >
            support@speakeasypolitical.com
          </a>
        </p>
      </div>
      <p
        className="mailSentSuccess-mailSentReturnToLogin"
        onClick={() => navigate('/')}
      >
        Return to Log in
      </p>
    </div>
  );
}

export default MailSentSuccess;
