import { createContext } from 'react';

export const AppContext = createContext({
  authenticated: false,
  apiUser: null,
  userType: null,
  isCartFull: true,
  setIsCartFull: (isFull) => {
    return isFull;
  },
});
