import { editableCampaignFieldNames } from '../../../../../../utils/campaignUtils';
import { formatNumberToCurrency } from '../../../../../../utils/currencyUtils';
import styles from './CampaignChangeOrderSummary.module.scss';
import _ from 'lodash';
import BudgetChangeSummary, {
  getBudgetChangeSummaryProps,
} from './components/budgetChangeSummary/BudgetChangeSummary';
import clsx from 'clsx';
import { BaseCampaignDetails, EditableCampaignFieldName } from 'models';
import { ReactNode } from 'react';
import FlightDatesChangeSummary, {
  getFlightDatesChangeSummaryProps,
} from './components/flightDatesChangeSummary/FlightDatesChangeSummary';

type Props = {
  campaignBeforeChanges: BaseCampaignDetails;
  campaignChanges?: BaseCampaignDetails;
  showFieldsWithNoChanges?: boolean;
  emphasizeTotal?: boolean;
};

const CampaignChangeOrderSummary = ({
  campaignBeforeChanges,
  campaignChanges,
  showFieldsWithNoChanges = true,
  emphasizeTotal = false,
}: Props) => {
  const budgetChangeSummaryProps = getBudgetChangeSummaryProps(
    campaignBeforeChanges,
    campaignChanges || {}
  );
  const flightDatesChangeSummaryProps = getFlightDatesChangeSummaryProps(
    campaignBeforeChanges,
    campaignChanges || {}
  );
  const changeSummaryComponents: {
    [key in EditableCampaignFieldName]: ReactNode;
  } = {
    flightDates: (
      <FlightDatesChangeSummary {...flightDatesChangeSummaryProps} />
    ),
    budget: <BudgetChangeSummary {...budgetChangeSummaryProps} />,
  };
  return (
    <div className={styles.container}>
      <div className={styles.breakdownContainer}>
        {editableCampaignFieldNames.map((fieldName, index) => {
          if (doChangesExistForCampaignField(campaignChanges, fieldName)) {
            return <div key={index}>{changeSummaryComponents[fieldName]}</div>;
          } else {
            if (showFieldsWithNoChanges)
              return <NoChanges key={index} fieldName={fieldName} />;
            return null;
          }
        })}
      </div>
      <div
        className={clsx(styles.line, styles.amountDue, {
          [styles.emphasizeAmountDue]: emphasizeTotal,
        })}
      >
        <span>Amount Due</span>
        <span>
          {formatNumberToCurrency(budgetChangeSummaryProps.additionalBudget)}
        </span>
      </div>
    </div>
  );
};

function doChangesExistForCampaignField(campaignChanges, fieldName) {
  if (!(fieldName in campaignChanges)) return false;

  const fieldValue = campaignChanges[fieldName];

  if (_.isNil(fieldValue)) return false;
  if (_.isObject(fieldValue) && _.isEmpty(fieldValue)) return false;
  return true;
}

const NoChanges = ({ fieldName }) => {
  const campaignFieldNameToTitle = {
    budget: 'Additional Budget',
    flightDates: 'Edited Flight Dates',
  };

  const title = campaignFieldNameToTitle[fieldName];
  if (!title) throw new Error(`Unknown field name "${fieldName}"`);

  return (
    <div className={clsx(styles.noChangesContainer, styles.line)}>
      <span>{title}</span>
      <span>No changes</span>
    </div>
  );
};

export default CampaignChangeOrderSummary;
