import React from 'react';
import './styles.scss';
import _ from 'lodash';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

export default class FlightDates extends React.Component {
  static defaultProps = {
    numberOfMonths: 2,
  };

  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.state = this.getInitialState();
  }

  getInitialState() {
    const campaignUpdate = this.props.campaignDetails;
    const today = new Date();
    const validStartDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 4
    );

    const flightDates = this.props.campaignDetails.flightDates || {
      from: undefined,
      to: undefined,
    };

    let { from, to } = flightDates;

    if (to && typeof to === 'string') {
      const toParts = to.split('-');
      to = new Date(`${toParts[1]}-${toParts[2]}-${toParts[0]}`);
    } else if (to) {
      to = '';
    }
    if (from && typeof from === 'string') {
      const fromParts = from.split('-');
      from = new Date(`${fromParts[1]}-${fromParts[2]}-${fromParts[0]}`);
    } else if (from) {
      from = '';
    }

    campaignUpdate.flightDates = flightDates;
    this.props.onHandleSetFlightDates(campaignUpdate);
    this.props.onHandleSuccess(false);

    return {
      from,
      to,
      validStartDate,
      disabledDays: [{ before: validStartDate }],
    };
  }

  handleDayClick(day) {
    if (day < this.state.validStartDate) {
      return;
    }
    this.props.onHandleSuccess(false);
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  }

  handleResetClick() {
    this.setState(this.getInitialState());
  }

  render() {
    const { from, to } = this.state;
    const modifiers = { start: from, end: to };
    const campaignUpdate = this.props.campaignDetails;
    campaignUpdate.flightDates = {
      from: this.state.from,
      to: this.state.to,
    };

    return (
      <div className="flightDates">
        <p>When would you like this ad to run? </p>
        <p>Hint: We recommend a minimum flight of 5 days</p>
        <DayPicker
          className="Selectable"
          numberOfMonths={this.props.numberOfMonths}
          selectedDays={[from, { from, to }]}
          modifiers={modifiers}
          onDayClick={this.handleDayClick}
          disabledDays={this.state.disabledDays}
        />
        <div className="flightDates-button-container">
          <button
            className="flightDates-reset-button"
            onClick={this.handleResetClick}
          >
            Clear dates
          </button>
          <button
            onClick={() => {
              this.props.onHandleSetFlightDates(campaignUpdate);
              this.props.onHandleSuccess(true);
              this.props.onHandleIsDateOpen(false);
            }}
            className={`flightDates-save-button  flightDates-save-button--${
              _.isEmpty(String(this.state.from)) &&
              _.isEmpty(String(this.state.to))
                ? 'disabled'
                : 'active'
            }`}
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}
