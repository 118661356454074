import React from 'react';
import './styles.scss';

function Card({ children, size, className }) {
  return (
    <div className={`card ${size ? 'card-' + size : ''} ${className || ''}`}>
      {children}
    </div>
  );
}

export default Card;
