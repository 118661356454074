import React, { useState } from 'react';
import arrowDown from './assets/chevron-down.svg';
import arrowUp from './assets/chevron-up.svg';
import styles from './styles.module.scss';
import { useSearchParams, useLocation } from 'react-router-dom';
import clsx from 'clsx';

function FaqDropdown({ title, id }) {
  const [dropdownActive, setDropdownActive] = useState(false);
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const handleDropdown = () => {
    setDropdownActive(!dropdownActive);
  };

  const dropdownContent = clsx({
    'dropdown-content-hide': !dropdownActive,
    'dropdown-content': dropdownActive,
  });

  return (
    <div className={styles['faq-dropdown-container']} key={id}>
      <div
        className={styles['dropdown-title']}
        onClick={() => {
          setDropdownActive(!dropdownActive);
        }}
      >
        {dropdownActive ? (
          <img src={arrowUp} className={styles.arrow} alt="chevron-up" />
        ) : (
          <img src={arrowDown} className={styles.arrow} alt="chevron-down" />
        )}
        {title}
      </div>
      <hr className={styles['dropdown-separator']} />
      <div className={styles[dropdownContent]}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industrys standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages, and more recently
        with desktop publishing software like Aldus PageMaker including versions
        of Lorem Ipsum.
      </div>
    </div>
  );
}

export default FaqDropdown;
