import React, { useState, useContext, useEffect, useRef } from 'react';
import { AppContext } from '../../../../context';
import './styles.scss';
import chevronDown from './assets/chevron-down.svg';
import chevronUp from './assets/chevron-up.svg';
import userIcon from './assets/user.svg';

import { useAuth } from '../../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

function UserDropdown() {
  const { logout } = useAuth();
  const { apiUser } = useContext(AppContext);
  const navigate = useNavigate();

  const [dropdownActive, setDropdownActive] = useState(false);

  const userDropdownRef = useRef(null);

  const userEmail = apiUser && apiUser.email;
  const userName = apiUser && apiUser.full_name;

  useEffect(() => {
    const clickOutsideUserDropdown = (e) => {
      if (
        dropdownActive &&
        userDropdownRef.current &&
        !userDropdownRef.current.contains(e.target)
      )
        setDropdownActive(false);
    };
    document.addEventListener('mousedown', clickOutsideUserDropdown);

    return () => {
      document.removeEventListener('mousedown', clickOutsideUserDropdown);
    };
  }, [dropdownActive]);

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  return (
    <div
      onClick={() => setDropdownActive(!dropdownActive)}
      className="userDropdown"
      ref={userDropdownRef}
    >
      <div style={{ display: 'flex' }}>
        <div className="userDropdown-user-information">
          <p className="userDropdown-user-information-name">{userName}</p>
          <p className="userDropdown-user-information-mail">{userEmail}</p>
        </div>
        {!dropdownActive ? (
          <img src={chevronDown} alt="chevron down icon" />
        ) : (
          <img src={chevronUp} alt="chevron up icon" />
        )}
      </div>
      <div
        className={
          dropdownActive
            ? 'userDropdown-options-container'
            : 'userDropdown-options-container-none'
        }
      >
        <div className="userDropdown-options-container-iconed-options">
          <img src={userIcon} alt="user icon" />
          <p className="userDropdown-options-container-options-text-iconed">
            Manage account
          </p>
        </div>
        {/*<div
          onClick={() => navigate('/dashboard/orders')}
          className="userDropdown-options-container-iconed-options"
        >
          <img src={lists} alt="lists icon" />
          <p className="userDropdown-options-container-options-text-iconed">
            My orders
          </p>
      </div>*/}

        <div
          onClick={handleLogout}
          className="userDropdown-options-container-options"
        >
          <p className="userDropdown-options-container-options-text">
            Sign out
          </p>
        </div>
        <div className="userDropdown-options-container-options">
          <p className="userDropdown-options-container-options-policy">
            Privacy policy
          </p>
        </div>
      </div>
    </div>
  );
}

export default UserDropdown;
