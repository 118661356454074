import React from 'react';
import './styles.scss';

function IconButton({
  icon,
  children,
  onClick,
  disabled = false,
  iconAltText,
}) {
  if (!icon) throw new Error('IconButton must have an icon.');
  return (
    <button disabled={disabled} className={`iconButton`} onClick={onClick}>
      <img src={icon} alt={iconAltText || 'button icon'} />
      {children}
    </button>
  );
}

export default IconButton;
