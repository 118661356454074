import React, { useState, useEffect, useRef } from 'react';
import './styles.scss';
import dotsVertical from './assets/dots-vertical.svg';
import { editCampaignStatus } from '../../../../../../services/campaign/campaignService';
import { useNavigate } from 'react-router-dom';

function ActionsDropdown(props) {
  const actionsDropdownRef = useRef();
  const [actionsdDropdownActive, setActionsDropdownActive] = useState(false);
  const navigate = useNavigate();
  const actions = [
    {
      label: 'Pause',
      display:
        props.campaignStatus === 'live' ||
        props.campaignStatus === 'pre-launch',
      enabled: true,
      requestStatus: 'pause-requested',
    },
    {
      label: 'Restart',
      display: props.campaignStatus === 'paused',
      enabled: true,
      requestStatus: `start-requested`,
    },
    {
      label: 'Cancel Request',
      display:
        props.campaignStatus === 'pause-requested' ||
        props.campaignStatus === 'start-requested',
      enabled: true,
      requestStatus:
        props.campaignStatus === 'pause-requested'
          ? 'live'
          : props.campaignStatus === 'start-requested'
          ? 'paused'
          : '',
    },
    {
      label: 'Edit',
      display:
        props.campaignStatus !== 'cancelled' &&
        props.campaignStatus !== 'ended',
      enabled:
        props.campaignStatus === 'draft' || props.campaignStatus === 'live',
      requestStatus: 'edit',
    },
  ];

  const handleStatusChange = async (campaignStatus) => {
    const campaignNewStatus = campaignStatus;

    const campaign = {
      id: props.campaignId,
      status: campaignNewStatus,
    };

    const response = await editCampaignStatus(campaign);
    if (response.status === 200) {
      setActionsDropdownActive(false);
      await props.refetchCampaigns();
    }
  };

  const handleEditCampaign = (campaignId, isCampaignEditable) => {
    if (isCampaignEditable) navigate(`/dashboard/campaigns/${campaignId}`);
  };

  useEffect(() => {
    const clickOutsideActionsDropdown = (e) => {
      const clickedElementClassList = e.target.classList
        ? e.target.classList
        : [];
      if (
        actionsdDropdownActive &&
        actionsDropdownRef.current &&
        clickedElementClassList.contains('action-element-true')
      ) {
        return;
      }
      if (actionsdDropdownActive && actionsDropdownRef.current === e.target)
        return;
      setActionsDropdownActive(false);
    };
    document.addEventListener('mousedown', clickOutsideActionsDropdown);

    return () => {
      document.removeEventListener('mousedown', clickOutsideActionsDropdown);
    };
  }, [actionsdDropdownActive]);

  const listItems = actions.map((action, index) => {
    if (action.display) {
      return (
        <li
          className={`action-element action-element-${action.enabled}`}
          key={index}
          onClick={
            action.label === 'Edit'
              ? () =>
                  handleEditCampaign(props.campaignId, props.isCampaignEditable)
              : () => handleStatusChange(action.requestStatus)
          }
        >
          {action.label}
        </li>
      );
    }

    return null;
  });

  const handleActionsDropdown = () => {
    setActionsDropdownActive(!actionsdDropdownActive);
  };

  return (
    <div className="actions-container">
      <img
        src={dotsVertical}
        alt="dots-vertical"
        onClick={handleActionsDropdown}
        ref={actionsDropdownRef}
        key={props.campaignId}
      />
      {actionsdDropdownActive ? (
        <div className="actions-dropdown">
          <ul className="actions-list">{listItems}</ul>
        </div>
      ) : null}
    </div>
  );
}

export default ActionsDropdown;
