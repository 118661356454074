import React, { useState } from 'react';
import './styles.scss';
import moment from 'moment';
import _ from 'lodash';
import arrowDown from './assets/chevron-down.svg';
import arrowUp from './assets/chevron-up.svg';
import {
  getCampaignByIdAsAdmin,
  editCampaignByIdAsAdmin,
} from '../../../../services/campaign/campaignService';
import Button from '../../../../components/button/Button';

function TableDropdown({ title, list, refechCampaigns }) {
  const [dropdownActive, setDropdownActive] = useState(false);
  const [onEdit, setOnEdit] = useState('');
  const [onSave, setOnSave] = useState(false);
  const [campaignToEdit, setCampaignToEdit] = useState({});

  const handleOnEdit = async (rowKey) => {
    if (!_.isEmpty(campaignToEdit) && onSave) {
      await editCampaignByIdAsAdmin(campaignToEdit);
      setOnEdit('');
      setOnSave(false);
      await refechCampaigns();

      return;
    }
    setOnEdit(rowKey);
    setOnSave(true);
  };

  const handleSelectOnchange = (campaignStatus, campaignId) => {
    let campaign = { id: campaignId, status: campaignStatus };
    setCampaignToEdit(campaign);
  };

  return (
    <div className="tableDropdownAdmin">
      <div
        onClick={() => setDropdownActive(!dropdownActive)}
        className="tableDropdown-dropdown"
      >
        {dropdownActive ? (
          <img
            className="tableDropdown-dropdown-arrows"
            src={arrowUp}
            alt="chevron-up"
          />
        ) : (
          <img
            className="tableDropdown-dropdown-arrows"
            src={arrowDown}
            alt="chevron-down"
          />
        )}
        <div style={{ display: 'flex' }}>
          <p className="tableDropdown-dropdown-title">{title}</p>
        </div>
      </div>
      <div
        className={
          dropdownActive
            ? 'tableDropdown-content'
            : 'tableDropdown-content-none'
        }
      >
        <table>
          <thead>
            <tr>
              <th>Campaign</th>
              <th>Flight Dates</th>
              <th>Time Remaining</th>
              <th>Budget</th>
              <th>Status</th>
              <th>Edit Campaign</th>
              <th>Assets</th>
            </tr>
          </thead>
          <tbody>
            {list.map((item, key) => {
              let itemKey = `${title}-${key}`;
              return (
                <tr key={itemKey}>
                  <td>{item.campaignName}</td>
                  <td>
                    {moment(String(item.startDate)).format('MM/DD/YY')} -{' '}
                    {moment(String(item.endDate)).format('MM/DD/YY')}
                  </td>
                  <td>
                    {Math.abs(
                      moment
                        .duration(
                          moment(item.startDate).diff(moment(item.endDate))
                        )
                        .asDays()
                    )}{' '}
                    days
                  </td>
                  <td>{item.budget}</td>
                  {onEdit !== itemKey ? (
                    <td id="dateAdded">{item.status}</td>
                  ) : (
                    <td>
                      <select
                        value={campaignToEdit.status}
                        onChange={(e) =>
                          handleSelectOnchange(e.target.value, item.id)
                        }
                        name="campaignStatus"
                        id="status"
                      >
                        <option value="pre-launch">Pre-Launch</option>
                        <option value="live">Live</option>
                        <option value="ended">Ended</option>
                        <option value="paused">Paused</option>
                        <option value="canceled">Canceled</option>
                      </select>
                    </td>
                  )}
                  <td onClick={() => handleOnEdit(itemKey)} id="dateAdded">
                    <Button size={'small'}>
                      {onEdit !== itemKey ? 'Edit status' : 'Save status'}
                    </Button>
                  </td>
                  <td
                    onClick={() => getCampaignByIdAsAdmin(item.id)}
                    id="dateAdded"
                  >
                    <Button type="secondary" size={'small'}>
                      Download media
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TableDropdown;
