import React from 'react';
import './styles.scss';
import HubspotForm from 'react-hubspot-form';
import closeIcon from './assets/close.svg';

function HubspotContactForm({ setShowHubspotContactForm }) {
  const closeHubspotContactForm = () => {
    setShowHubspotContactForm(false);
  };

  return (
    <div className="modal-background">
      <div className="modal-container">
        <img
          src={closeIcon}
          alt="Close Icon"
          className="close-icon"
          onClick={closeHubspotContactForm}
        />
        <HubspotForm
          region="na1"
          portalId="2204631"
          formId="2b04285c-4bd0-42b6-9eca-a6ba96522a0a"
          loading={<div className="loading">Loading...</div>}
          onSubmit={closeHubspotContactForm}
          onReady={(form) => console.log('Form ready!')}
          onFormSubmitted={closeHubspotContactForm}
        />
      </div>
    </div>
  );
}

export default HubspotContactForm;
