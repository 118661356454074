import React, { useState, useContext, useLayoutEffect } from 'react';
import './styles.scss';
import Card from '../../../../components/card/Card';
import imageNoSelected from './assets/imageNoSelected.svg';
import imageSelected from './assets/imageSelected.svg';
import OTTSelectedImg from './assets/ottSelected.svg';
import OTTNoSelectedImg from './assets/ottNoSelected.svg';
import PreRollNoSelectredImg from './assets/preRollNoSelected.svg';
import preRollSelectedImg from './assets/preRollSelected.svg';
import { CampaignContext } from '../../Campaign';

function AddType() {
  const { campaignDetails, setCampaignDetails } = useContext(CampaignContext);
  const [selectedCampaignType, setSelectedCampaignType] = useState('');
  const [selectedVideoType, setSelectedVideoType] = useState('');

  // TODO: Investigate why this useLayoutEffect with empty function
  // call is needed and remove if not needed.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useLayoutEffect(() => {}, [selectedCampaignType]);

  return (
    <Card>
      <div className="addTypes">
        <p className="addTypes-title">What type of ad are we running?</p>
        <div className="addTypes-container">
          <div className="addTypes-container-column">
            <h3>Static</h3>
            <img
              id="imgBanner"
              onClick={() => {
                setSelectedCampaignType('banner');
                setSelectedVideoType('');
              }}
              src={imageNoSelected}
              alt="banner not selected"
              className={
                selectedCampaignType === 'banner'
                  ? 'addTypes-imgNoneDisplayed'
                  : 'addTypes-imgDisplayed'
              }
            />
            <img
              id="imgBanner"
              onClick={() => {
                setSelectedCampaignType('banner');
                setSelectedVideoType('');
              }}
              src={imageSelected}
              alt="banner selected"
              className={
                selectedCampaignType === 'banner'
                  ? 'addTypes-imgDisplayed'
                  : 'addTypes-imgNoneDisplayed'
              }
            />
          </div>
          <div className="addTypes-container-column">
            <h3>Video</h3>
            <img
              id="imgBanner"
              onClick={() => {
                setSelectedCampaignType('video');
                setSelectedVideoType('ott');
              }}
              src={OTTNoSelectedImg}
              alt="ott video no selected"
              className={
                selectedVideoType === 'ott'
                  ? 'addTypes-imgNoneDisplayed'
                  : 'addTypes-imgDisplayed'
              }
            />
            <img
              id="imgBanner"
              onClick={() => {
                setSelectedCampaignType('video');
                setSelectedVideoType('ott');
              }}
              src={OTTSelectedImg}
              alt="ott video selected"
              className={
                selectedVideoType === 'ott'
                  ? 'addTypes-imgDisplayed'
                  : 'addTypes-imgNoneDisplayed'
              }
            />
            <img
              id="videoBanner"
              onClick={() => {
                setSelectedCampaignType('video');
                setSelectedVideoType('pre-roll');
              }}
              src={PreRollNoSelectredImg}
              alt="pre roll no selected"
              className={
                selectedVideoType === 'pre-roll'
                  ? 'addTypes-videoNoneDisplayed'
                  : 'addTypes-videDisplayed'
              }
            />
            <img
              id="videoBanner"
              onClick={() => {
                setSelectedCampaignType('video');
                setSelectedVideoType('pre-roll');
              }}
              src={preRollSelectedImg}
              alt="pre roll selected"
              className={
                selectedVideoType === 'pre-roll'
                  ? 'addTypes-videDisplayed'
                  : 'addTypes-videoNoneDisplayed'
              }
            />
          </div>
        </div>
        <button
          onClick={() => {
            setCampaignDetails({
              ...campaignDetails,
              type: selectedCampaignType,
              videoType: selectedVideoType,
            });
          }}
          className="addTypes-nextButton"
        >
          Next
        </button>
      </div>
    </Card>
  );
}

export default AddType;
