import React from 'react';
import styles from './DetailLine.module.scss';

const DetailLine = ({ name, value }) => {
  return (
    <div className={styles.container}>
      <span className={styles.name}>{name}:</span>
      <span className={styles.value}>{value}</span>
    </div>
  );
};

export default DetailLine;
