import crossButtonIcon from './assets/crossButton.svg';
import videoPlaceholderImage from './assets/video.svg';
import styles from './MediaThumbnail.module.scss';

type Props = {
  src: React.ComponentProps<'img'>['src'];
  type: 'video' | 'banner';
  caption?: string;
  showDiscardButton: boolean;
  onClickDiscard: () => void;
};

const MediaThumbnail = ({
  src,
  type,
  caption,
  showDiscardButton = false,
  onClickDiscard,
}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.imgOuterWrapper}>
        <div className={styles.imgContainer}>
          <img
            className={styles.thumbnail}
            src={type === 'video' && !src ? videoPlaceholderImage : src}
            alt={caption}
          />
        </div>
      </div>
      <div className={styles.caption}>{caption}</div>
      {showDiscardButton ? (
        <button className={styles.discardButton} onClick={onClickDiscard}>
          <img src={crossButtonIcon} alt="X with circle drawn around it" />
        </button>
      ) : null}
    </div>
  );
};
export default MediaThumbnail;
