import { CampaignDetails } from 'models';
import styles from './FlightDatesChangeSummary.module.scss';
import { formatDateRange } from '../../../../../../../../utils/dateUtils';

type FlightDatesChangeSummaryProps = {
  currentFlightDates: object;
  newFlightDates: object;
};

const FlightDatesChangeSummary = ({ currentFlightDates, newFlightDates }) => {
  return (
    <div>
      <div className={styles.section}>
        <div className={styles.line}>
          <span>Current Flight Dates</span>
          <span>
            {formatDateRange(currentFlightDates.from, currentFlightDates.to)}
          </span>
        </div>
        <div className={styles.line}>
          <span>New Flight Dates</span>
          <span>{formatDateRange(newFlightDates.from, newFlightDates.to)}</span>
        </div>
      </div>
    </div>
  );
};

export const getFlightDatesChangeSummaryProps = (
  campaignBeforeChanges: CampaignDetails,
  campaignChanges: CampaignDetails
): FlightDatesChangeSummaryProps => {
  const currentFlightDates = campaignBeforeChanges.flightDates;

  if (!currentFlightDates)
    throw new Error('Unexpected state: campaign with undefined flight dates.');

  const newFlightDates = campaignChanges.flightDates || currentFlightDates;

  return {
    currentFlightDates,
    newFlightDates,
  };
};

export default FlightDatesChangeSummary;
