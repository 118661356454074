import { createContext, useContext, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import instance from '../services/axiosConfig';
import { auth } from '../firebase';

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});

  const createUser = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const sendPasswordReset = (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  const signIn = (email, password, callback) => {
    return signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        instance.defaults.headers.Authorization = `Bearer ${userCredential.user.accessToken}`;
        setUser(userCredential.user);
        callback();
      })
      .catch((error) => callback(error));
  };

  const logout = () => {
    setUser(null);
    return signOut(auth);
  };

  return (
    <UserContext.Provider
      value={{ createUser, sendPasswordReset, user, logout, signIn }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(UserContext);
};
