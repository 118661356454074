import React, { useState, useEffect, useContext } from 'react';
import './styles.scss';
import _ from 'lodash';
import SearchBar from './components/searchBar/SearchBar';
import BuildTargetAudience from './components/buildTargetAudience/BuildTargetAudience';
import { getAllAudiences } from '../../../../../../services/audience/audienceService';
import check from './components/buildTargetAudience/assets/check-circle.svg';
import { CampaignContext } from '../../../../Campaign';

function TargetAudience({
  onHandleSuccess,
  onHandleIsAudienceOpen,
  onHandleNextSectionOpen,
}) {
  const { campaignDetails, setCampaignDetails } = useContext(CampaignContext);
  const [shouldCreateNewTargetAudience, setShouldCreateNewTargetAudience] =
    useState(false);
  const [uploadNewTargetAudience, setUploadNewTargetAudience] = useState(false);
  const [targetAudience, setNewTargetAudience] = useState({});
  const [audienceList, setAudienceList] = useState([]);
  const [filterByAudienceName, setFilterByAudienceName] = useState('');
  const [isElementSelected, setIsElementSelected] = useState(false);

  const regExp = RegExp(
    `.*${filterByAudienceName.toLowerCase().split('').join('.*')}.*`
  );
  const handlerTargetAudience = () => {
    setCampaignDetails({ ...campaignDetails, targetAudience });
    onHandleSuccess(true);
    onHandleIsAudienceOpen(false);
    onHandleNextSectionOpen(true);
  };

  useEffect(() => {
    fetchAllTargetAudiences();
  }, [isElementSelected]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchAllTargetAudiences = async () => {
    let list = await getAllAudiences();

    if (!_.isEmpty(list)) {
      setAudienceList(list);
      setNewTargetAudience({
        id: campaignDetails.targetAudience?.id,
        name: campaignDetails.targetAudience?.name,
      });
      setIsElementSelected(true);
    }
  };

  const setToActiveRow = (audienceInfo) => {
    setIsElementSelected(true);
    onHandleSuccess(false);
    setNewTargetAudience({
      id: audienceInfo.id,
      name: audienceInfo.attributes.name,
    });

    if (targetAudience.id === audienceInfo.id) {
      setIsElementSelected(!isElementSelected);
    }
  };

  return (
    <div className="targetAudience">
      <p className="targetAudience-title">
        Now let&apos;s build your target audience
      </p>

      {shouldCreateNewTargetAudience === false &&
      uploadNewTargetAudience === false ? (
        <div className="targetAudience-list-container">
          <div className="targetAudience-buttons-container">
            <button
              style={{ display: 'none' }}
              onClick={() =>
                setUploadNewTargetAudience(!uploadNewTargetAudience)
              }
              className="targetAudience-upload-button"
            >
              Upload Van
            </button>
            <button
              onClick={() =>
                setShouldCreateNewTargetAudience(!shouldCreateNewTargetAudience)
              }
              className="targetAudience-new-button"
            >
              Build new
            </button>
          </div>
          <SearchBar onHandleFilterByAudienceName={setFilterByAudienceName} />
          <div className="targetAudience-list-container-list">
            {audienceList
              .filter((audience) => {
                return audience.attributes.name.toLowerCase().match(regExp);
              })
              .map((audience, key) => {
                return (
                  <div
                    onClick={() => setToActiveRow(audience)}
                    className={
                      isElementSelected &&
                      String(targetAudience.id) === String(audience.id)
                        ? 'targetAudience-list-container-element-selected'
                        : 'targetAudience-list-container-element'
                    }
                    key={`audience-${key}`}
                  >
                    <p className={`targetAudience-list-container-options`}>
                      {audience.attributes.name}
                    </p>
                    <img src={check} alt="check" />
                  </div>
                );
              })}
          </div>
        </div>
      ) : (
        <></>
      )}

      {shouldCreateNewTargetAudience === true ? (
        <BuildTargetAudience
          onHandlerGoBack={setShouldCreateNewTargetAudience}
          goBack={shouldCreateNewTargetAudience}
          onHandleNewTargetAudience={setNewTargetAudience}
        />
      ) : (
        <></>
      )}
      <button
        onClick={() => handlerTargetAudience(campaignDetails)}
        className={`targetAudience-save-button  targetAudience-save-button--${
          _.isEmpty(targetAudience) ||
          (_.isEmpty(targetAudience.name) &&
            _.isEmpty(targetAudience.state) &&
            _.isEmpty(targetAudience.geographicArea))
            ? 'disabled'
            : 'active'
        }`}
      >
        Save
      </button>
    </div>
  );
}

export default TargetAudience;
